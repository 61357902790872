export default class Fee {
    constructor({
        id = 0,
        name = {en: '', es: ''},
        rate = 0,
        profit = false,
    } = {}) {
        this.id = id;
        this.name = name;
        this.rate = rate;
        this.profit = profit;
    }
}
