export default class OrdersReports {
    constructor({
        id = 0,
        topic = '',
        status = '',
        createdAt = '',
        user = {},
        seller = {},
        order = {},
        reviews = [],
        payments = [],
        shipments = [],
        isRead = false,
    } = {}) {
        this.id = id;
        this.topic = topic;
        this.status = status;
        this.createdAt = createdAt;
        this.user = user;
        this.seller = seller;
        this.order = order;
        this.reviews = reviews;
        this.payments = payments;
        this.shipments = shipments;
        this.isRead = isRead;
    }

    get buyerFullName() {
        if (!this.user?.id) {
            return 'n/a';
        }
        return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
    }

    get sellerFullName() {
        if (!this.seller?.id) {
            return 'n/a';
        }
        return `${this.seller.firstName || ''} ${this.seller.lastName || ''}`;
    }
}
