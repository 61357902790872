import React from 'react';
import {useIntl} from 'react-intl';
import Col from 'react-bootstrap/Col';
import BaseForm from 'react-bootstrap/Form';
import * as Yup from 'yup';
import {Formik, FieldArray} from 'formik';

import {ReturnDetailStatus} from '../../../common/config/constants';
import Input from '../Input';
import LoadingButton from '../LoadingButton';

export default function ConfirmForm(props) {
    const intl = useIntl();
    const detailSchema = {
        reviewComments: Yup.string(),
        status: Yup.string().required(
            intl.formatMessage({
                id: 'RETURNS.VALIDATION.DETAIL_STATUS_MISSING',
            }),
        ),
        id: Yup.number().required(''),
    };
    const validationSchema = Yup.object({
        details: Yup.array().of(Yup.object().shape(detailSchema)),
    });
    // Status as plural
    const status = Object.values(ReturnDetailStatus).filter(
        (status) => status !== ReturnDetailStatus.PENDING,
    );

    const onSubmit = ({details}) => {
        props.handleSubmit({returnDetails: details});
    };

    const getInitialValues = () => {
        return {
            details: props.details.map((detail, i) => {
                return {
                    id: detail.id,
                    reviewComments: '',
                    status: '',
                    index: i,
                };
            }),
        };
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <FieldArray
                            name='details'
                            render={(arrayHelpers) => (
                                <div>
                                    {values.details &&
                                        values.details.map((detail, i) => (
                                            <div key={`detail-${i}`}>
                                                <BaseForm.Row>
                                                    <Col sm={6}>
                                                        <label>
                                                            {intl.formatMessage(
                                                                {
                                                                    id:
                                                                        'RETURNS.NAME_LABEL',
                                                                },
                                                            )}
                                                        </label>
                                                        <p>
                                                            {props.details[
                                                                detail.index
                                                            ] &&
                                                                props.details[
                                                                    detail.index
                                                                ].name}
                                                        </p>
                                                    </Col>
                                                    <Col sm={6}>
                                                        <label>
                                                            {intl.formatMessage(
                                                                {
                                                                    id:
                                                                        'RETURNS.PRODUCT_LABEL',
                                                                },
                                                            )}
                                                        </label>
                                                        <br />
                                                        <img
                                                            hidden={
                                                                props.details[
                                                                    detail.index
                                                                ] &&
                                                                !props.details[
                                                                    detail.index
                                                                ].pictureUrl
                                                            }
                                                            className='img-fluid'
                                                            src={
                                                                props.details[
                                                                    detail.index
                                                                ] &&
                                                                props.details[
                                                                    detail.index
                                                                ].pictureUrl
                                                            }
                                                            alt={`product`}
                                                            width='200px'
                                                        />
                                                    </Col>
                                                    <Col
                                                        className='mt-4'
                                                        sm={6}
                                                    >
                                                        <label>
                                                            {intl.formatMessage(
                                                                {
                                                                    id:
                                                                        'RETURNS.PRICE_LABEL',
                                                                },
                                                            )}
                                                        </label>
                                                        <p>
                                                            {props.details[
                                                                detail.index
                                                            ] &&
                                                                '$' +
                                                                    parseFloat(
                                                                        props
                                                                            .details[
                                                                            detail
                                                                                .index
                                                                        ].price,
                                                                    ).toFixed(
                                                                        2,
                                                                    )}
                                                        </p>
                                                    </Col>
                                                    <Col
                                                        className='mt-4'
                                                        sm={6}
                                                    >
                                                        <label>
                                                            {intl.formatMessage(
                                                                {
                                                                    id:
                                                                        'RETURNS.REASON_LABEL',
                                                                },
                                                            )}
                                                        </label>
                                                        <p>
                                                            {props.details[
                                                                detail.index
                                                            ] &&
                                                                intl.formatMessage(
                                                                    {
                                                                        id: `RETURNS.REASON_${props.details[
                                                                            detail
                                                                                .index
                                                                        ].reason.toUpperCase()}`,
                                                                    },
                                                                )}
                                                        </p>
                                                    </Col>
                                                    <Input
                                                        sm='6'
                                                        className='mt-4'
                                                        type='select'
                                                        label={intl.formatMessage(
                                                            {
                                                                id:
                                                                    'RETURNS.DETAIL_STATUS_LABEL',
                                                            },
                                                        )}
                                                        placeholder={intl.formatMessage(
                                                            {
                                                                id:
                                                                    'RETURNS.DETAIL_STATUS_PLACEHOLDER',
                                                            },
                                                        )}
                                                        name={`details.${i}.status`}
                                                        value={
                                                            values.details[i]
                                                                .status
                                                        }
                                                        onChange={handleChange}
                                                        error={
                                                            errors.details &&
                                                            errors.details
                                                                .length > 0
                                                                ? errors
                                                                      .details[
                                                                      i
                                                                  ].status
                                                                : ''
                                                        }
                                                        isInvalid={
                                                            errors.details &&
                                                            errors.details
                                                                .length > 0
                                                                ? errors
                                                                      .details[
                                                                      i
                                                                  ].status
                                                                : ''
                                                        }
                                                        children={status.map(
                                                            (
                                                                detailStatus,
                                                                i,
                                                            ) => (
                                                                <option
                                                                    key={`status-${i}`}
                                                                    value={
                                                                        detailStatus
                                                                    }
                                                                >
                                                                    {intl.formatMessage(
                                                                        {
                                                                            id: `RETURNS.DETAIL_STATUS_${detailStatus.toUpperCase()}`,
                                                                        },
                                                                    )}
                                                                </option>
                                                            ),
                                                        )}
                                                    />
                                                    <Input
                                                        sm='6'
                                                        className='mt-4'
                                                        type='textarea'
                                                        label={intl.formatMessage(
                                                            {
                                                                id:
                                                                    'RETURNS.DETAIL_REVIEW_COMMENTS_LABEL',
                                                            },
                                                        )}
                                                        placeholder={intl.formatMessage(
                                                            {
                                                                id:
                                                                    'RETURNS.DETAIL_REVIEW_COMMENT_PLACEHOLDER',
                                                            },
                                                        )}
                                                        name={`details.${i}.reviewComments`}
                                                        value={
                                                            values.details[i]
                                                                .reviewComments
                                                        }
                                                        onChange={handleChange}
                                                        error={
                                                            errors.details &&
                                                            errors.details
                                                                .length > 0
                                                                ? errors
                                                                      .details[
                                                                      i
                                                                  ]
                                                                      .reviewComments
                                                                : ''
                                                        }
                                                        isInvalid={
                                                            errors.details &&
                                                            errors.details
                                                                .length > 0
                                                                ? errors
                                                                      .details[
                                                                      i
                                                                  ]
                                                                      .reviewComments
                                                                : ''
                                                        }
                                                    />
                                                </BaseForm.Row>
                                                {i + 1 !==
                                                    values.details.length && (
                                                    <hr />
                                                )}
                                            </div>
                                        ))}
                                    <div className='d-flex justify-content-between'>
                                        <div></div>
                                        <div className='text-right'>
                                            <LoadingButton
                                                variant='primary'
                                                className='btn-bold'
                                                type='submit'
                                                onClick={handleSubmit}
                                                loading={props.loading}
                                            >
                                                {intl.formatMessage({
                                                    id:
                                                        'RETURNS.CONFIRM_DETAILS_BUTTON_TEXT',
                                                })}
                                            </LoadingButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </BaseForm>
                </>
            )}
        </Formik>
    );
}
