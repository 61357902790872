import React, {useState} from 'react';
import Sidebar from './Sidebar';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import LanguageSelector from '../LanguageSelector';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {store} from '../../common/redux/StoreReducers';
import {useIntl} from 'react-intl';
import Alert from 'react-bootstrap/Alert';

import './Layout.scss';
import './TopNavbar.scss';

export default function withLayout(WrappedComponent, title) {
    function shouldDisplaySuccessMessage(props) {
        if (props.location.state) {
            if (props.location.state.successMessage) {
                return true;
            }
        }
        return false;
    }

    function HOC(props) {
        const intl = useIntl();
        const [isSidebarOpen, setIsSidebarOpen] = useState(false);
        return (
            <div id='sidebar-container'>
                <Sidebar
                    onToggleTopMenu={(e) => {
                        setIsSidebarOpen(!isSidebarOpen);
                    }}
                />
                <div id='content-container' className='overflow-hidden'>
                    <Navbar
                        expand='md'
                        expanded={isSidebarOpen}
                        id='top-navbar'
                        bg='light'
                        className='border-bottom'
                    >
                        <Navbar.Text href='#home'>{title}</Navbar.Text>

                        <Navbar.Collapse id='top-navbar-nav'>
                            <Nav className='ml-auto'>
                                <Nav.Link as={NavLink} to='/'>
                                    Dashboard
                                </Nav.Link>
                                <LanguageSelector />
                                <NavDropdown
                                    className='top-navbar-user-dropdown'
                                    title={
                                        <>
                                            <Image
                                                src='https://randomuser.me/api/portraits/men/46.jpg'
                                                rounded
                                                width='31'
                                                height='31'
                                                className='mr-2'
                                            />{' '}
                                            {props.user.firstName}
                                        </>
                                    }
                                    alignRight
                                >
                                    <NavDropdown.Item
                                        onClick={() => {
                                            store.dispatch(props.logout());
                                        }}
                                    >
                                        {intl.formatMessage({
                                            id: 'AUTH.LOGOUT.BUTTON',
                                        })}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <div className='container-fluid' id='main-container'>
                        <Alert
                            variant='success'
                            className='mb-4'
                            show={shouldDisplaySuccessMessage(props)}
                        >
                            <p className='mb-0'>
                                {props.location.state &&
                                props.location.state.successMessage
                                    ? props.location.state.successMessage
                                    : ''}
                            </p>
                        </Alert>
                        <WrappedComponent {...props} />
                    </div>
                </div>
            </div>
        );
    }

    const logout = function () {
        return {
            type: 'LOGOUT',
        };
    };

    const mapStateToProps = function (state) {
        return {
            user: state.sessionReducer.user,
            title: state.testReducer.title,
            content: state.testReducer.content,
        };
    };

    const mapDispatchToProps = {
        logout: logout,
    };

    return connect(mapStateToProps, mapDispatchToProps)(HOC);
}
