import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Category from '../../models/Category';

const categories = {
    get: async (search = '') => {
        const {data: response} = await API.get(`/categories?search=${search}`);

        const list = new PaginatedList();
        list.items = response.data.map((i) => new Category(i));

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/categories/${id}`);

        return {
            category: new Category(response.data),
        };
    },
    save: async (input) => {
        const {data: response} = await API.post('/categories', input);
        return {
            category: new Category(response.data),
        };
    },
    update: async (input, id) => {
        const {data: response} = await API.put(`/categories/${id}`, input);
        return {
            category: new Category(response.data),
        };
    },
    delete: async (id) => {
        await API.delete(`/categories/${id}`);
        return {};
    },
    getAttributes: async () => {
        const {data: response} = await API.get('/attributes');

        return {
            attributes: response.data,
        };
    },
};

export default categories;
