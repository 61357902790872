import {API} from '../API';
import PaginatedList from '../PaginatedList';
import User from '../../models/User';

const users = {
    get: (pageNumber = 1, keyword = '') =>
        new Promise((resolve, reject) => {
            return API.get(`/users?page=${pageNumber}&search=${keyword}`)
                .then((response) => {
                    let userList = new PaginatedList();
                    userList.items = response.data.data.map((data) => {
                        let user = new User();
                        user.id = data.id;
                        user.firstName = data.firstName;
                        user.lastName = data.lastName;
                        user.email = data.email;
                        user.roles = data.roles;
                        return user;
                    });
                    resolve(userList);
                })
                .catch((err) => reject(err));
        }),
    detail: (id) =>
        new Promise((resolve, reject) => {
            return API.get(`/users/${id}`)
                .then((response) => {
                    let user = new User(
                        response.data.data.id,
                        response.data.data.firstName,
                        response.data.data.lastName,
                        response.data.data.email,
                        response.data.data.phoneCountry,
                        response.data.data.phone,
                        response.data.data.pictureUrl,
                        response.data.data.roles,
                        response.data.data.isActive,
                        response.data.data.permissions,
                        response.data.data.isSuperAdmin,
                    );
                    // Fill user with response data
                    resolve({
                        user: user,
                    });
                })
                .catch((err) => reject(err));
        }),
    save: (params) =>
        new Promise((resolve, reject) => {
            return API.post(`/users`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    type: 'formData',
                },
            })
                .then((response) => {
                    let user = new User();
                    // Fill user with response data
                    resolve({
                        user: user,
                    });
                })
                .catch((err) => reject(err));
        }),
    update: (id, params) =>
        new Promise((resolve, reject) => {
            return API.put(`/users/${id}`, params, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    type: 'formData',
                },
            })
                .then((response) => {
                    let user = new User();
                    // Fill user with response data
                    resolve({
                        user: user,
                    });
                })
                .catch((err) => reject(err));
        }),
    delete: (id) =>
        new Promise((resolve, reject) => {
            return API.delete(`/users/${id}`)
                .then((response) => {
                    // Pass success message to view
                    resolve({});
                })
                .catch((err) => reject(err));
        }),
    activate: (id) =>
        new Promise((resolve, reject) => {
            return API.put(`/users/${id}/activate`)
                .then((response) => {
                    resolve({});
                })
                .catch((err) => reject(err));
        }),
    deactivate: (id) =>
        new Promise((resolve, reject) => {
            return API.put(`/users/${id}/deactivate`)
                .then((response) => {
                    resolve({});
                })
                .catch((err) => reject(err));
        }),
};

export default users;
