import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Role from '../../models/Role';
import Permission from '../../models/Permission';

const roles = {
    get: (pageNumber = 1, keyword = '') =>
        new Promise((resolve, reject) => {
            return API.get(`/roles?page=${pageNumber}&search=${keyword}`)
                .then((response) => {
                    let roleList = new PaginatedList();
                    roleList.page = response.data.meta.page;
                    roleList.pageCount = response.data.meta.lastPage;
                    roleList.pageSize = response.data.meta.perPage;
                    roleList.total = response.data.meta.total;
                    roleList.items = response.data.data.map((data) => {
                        return new Role(data.id, data.name);
                    });
                    resolve(roleList);
                })
                .catch((err) => reject(err));
        }),
    detail: (id) =>
        new Promise((resolve, reject) => {
            return API.get(`/roles/${id}`)
                .then((response) => {
                    let role = new Role(
                        response.data.data.id,
                        response.data.data.name,
                        [],
                        response.data.data.userCount,
                    );
                    role.permissions = response.data.data.permissions.map(
                        (rolePermission) =>
                            new Permission(
                                rolePermission.code,
                                rolePermission.description,
                                rolePermission.group,
                            ),
                    );
                    resolve({
                        role: role,
                    });
                })
                .catch((err) => reject(err));
        }),
    save: (params) =>
        new Promise((resolve, reject) => {
            return API.post(`/roles`, params)
                .then((response) => {
                    let role = new Role();
                    // Fill role with response data
                    resolve({
                        role: role,
                    });
                })
                .catch((err) => reject(err));
        }),
    update: (id, params) =>
        new Promise((resolve, reject) => {
            return API.put(`/roles/${id}`, params)
                .then((response) => {
                    let role = new Role(
                        response.data.data.id,
                        response.data.data.name,
                        [],
                        response.data.data.userCount,
                    );
                    role.permissions = response.data.data.permissions.map(
                        (rolePermission) =>
                            new Permission(
                                rolePermission.code,
                                rolePermission.description,
                                rolePermission.group,
                            ),
                    );
                    resolve({
                        role: role,
                    });
                })
                .catch((err) => reject(err));
        }),
    delete: (id) =>
        new Promise((resolve, reject) => {
            return API.delete(`/roles/${id}`)
                .then((response) => {
                    resolve({});
                })
                .catch((err) => reject(err));
        }),
    constants: {
        ALL: 0,
    },
};

export default roles;
