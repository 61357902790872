export default class Order {
    constructor({
        id = 0,
        folio = '',
        total = '0',
        subtotal = '0',
        shipping = '0',
        incomeTax = '0',
        vat = '0',
        payable = '0',
        status = '',
        createdAt = '',
        payedAt = '',
        payedReference = '',
        user = {},
        seller = {},
        shipment = {},
        shipments = [],
        payments = [],
        fees = [],
        details = [],
        review = {},
    } = {}) {
        this.id = id;
        this.folio = folio;
        this.total = parseFloat(total);
        this.subtotal = parseFloat(subtotal);
        this.shipping = parseFloat(shipping);
        this.incomeTax = parseFloat(incomeTax);
        this.vat = parseFloat(vat);
        this.fees = fees;
        this.payable = parseFloat(payable);
        this.status = status;
        this.createdAt = createdAt;
        this.payedAt = payedAt;
        this.payedReference = payedReference;
        this.user = user;
        this.seller = seller;
        this.shipment = shipment;
        this.shipments = shipments;
        this.payments = payments;
        this.details = details;
        this.review = review;
    }

    get buyerFullName() {
        return `${this.user.firstName || ''} ${this.user.lastName || ''}`;
    }

    get sellerFullName() {
        return `${this.seller.firstName || ''} ${this.seller.lastName || ''}`;
    }
}
