import React from 'react';
import {injectIntl} from 'react-intl';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Rater from 'react-rater';
import {NavLink} from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Order from '../../common/models/Order';
import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import Modal from 'react-bootstrap/Modal';
import PaymentForm from '../../components/form/orders/PaymentForm';
import LoadingButton from '../../components/form/LoadingButton';
import BankAccount from '../../common/models/BankAccount';
import {ShipmentStatus} from '../../common/config/constants';
import 'react-rater/lib/react-rater.css';
import './OrderDetail.scss';

export class OrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: new Order(),
            bankAccount: new BankAccount(),
            error: '',
            isLoadErrorModalOpen: false,
            isSellerPaymentModalOpen: false,
            isDeleteReviewModalOpen: false,
            paymentDoneMessage: '',
            reviewDeletedMessage: '',
            loading: false,
        };
    }

    componentDidMount() {
        return this.fetchOrder();
    }

    fetchOrder = async () => {
        this.setState({loading: true});
        try {
            const {order} = await API.orders.detail(this.props.match.params.id);
            const {bankAccount} = await API.bankAccounts.getByUser(
                order.seller.id,
            );

            this.setState({
                order,
                bankAccount,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
                isLoadErrorModalOpen: true,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        try {
            const {order} = await API.orders.payToSeller(
                input,
                this.props.match.params.id,
            );

            this.setState(
                {
                    order,
                    loading: false,
                    isSellerPaymentModalOpen: false,
                    paymentDoneMessage: this.props.intl.formatMessage({
                        id: 'ORDERS.PAYMENT_TO_SELLER_DONE',
                    }),
                },
                () => this.cleanAlertsMessages('paymentDoneMessage'),
            );
        } catch (e) {
            this.setState(
                {
                    loading: false,
                    isSellerPaymentModalOpen: false,
                    error: this.props.intl.formatMessage({
                        id: 'ORDERS.PAYMENT_TO_SELLER_ERROR',
                    }),
                },
                () => this.cleanAlertsMessages('error'),
            );
        }
    };

    togglePaymentModal = () => {
        this.setState({
            isSellerPaymentModalOpen: !this.state.isSellerPaymentModalOpen,
        });
    };

    toggleDeleteReviewModal = () => {
        this.setState({
            isDeleteReviewModalOpen: !this.state.isDeleteReviewModalOpen,
        });
    };

    deleteReview = async () => {
        this.setState({loading: true});

        try {
            const {order} = await API.orders.deleteReview(
                this.props.match.params.id,
            );

            this.setState(
                {
                    order,
                    loading: false,
                    isDeleteReviewModalOpen: false,
                    reviewDeletedMessage: this.props.intl.formatMessage({
                        id: 'ORDERS.DELETE_REVIEW_DONE',
                    }),
                },
                () => this.cleanAlertsMessages('reviewDeletedMessage'),
            );
        } catch (e) {
            this.setState(
                {
                    loading: false,
                    isDeleteReviewModalOpen: false,
                    error: this.props.intl.formatMessage({
                        id: 'ORDERS.DELETE_REVIEW_ERROR',
                    }),
                },
                () => this.cleanAlertsMessages('error'),
            );
        }
    };

    cleanAlertsMessages = (field) => {
        setTimeout(() => {
            this.setState({
                [field]: '',
            });
        }, 5000);
    };

    render() {
        const canPaySeller =
            this.state.order.shipments.length > 0 &&
            this.state.order.shipments[this.state.order.shipments.length - 1]
                .status === ShipmentStatus.DELIVERED &&
            !this.state.order.payedAt;
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <Alert
                    variant='success'
                    className='mb-4'
                    show={
                        !!this.state.paymentDoneMessage ||
                        !!this.state.reviewDeletedMessage
                    }
                    dismissible={false}
                >
                    <p className='mb-0'>
                        {this.state.paymentDoneMessage ||
                            this.state.reviewDeletedMessage}
                    </p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Can run='ORDERS_PAYTOSELLER'>
                        <Button
                            className='btn-info btn-bold mx-2 btn'
                            disabled={!canPaySeller}
                            onClick={this.togglePaymentModal}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.PAY_SELLER',
                            })}
                        </Button>
                    </Can>
                    <Button
                        as={NavLink}
                        to='/orders'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card data-test='detailOrderComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'ORDERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.ID_LABEL',
                                    })}
                                    {' / #'}
                                </label>
                                <p>{this.state.order.id}</p>
                                <p>#{this.state.order.folio}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.ORDER_STATUS_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.order.status
                                        ? this.props.intl.formatMessage({
                                              id: `ORDERS.STATUS_${this.state.order.status.toUpperCase()}`,
                                          })
                                        : null}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.BUYER_NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.order.buyerFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.SELLER_NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.order.sellerFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.SUBTOTAL_LABEL',
                                    })}
                                </label>
                                <p>$ {this.state.order.subtotal.toFixed(2)}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.SHIPPING_LABEL',
                                    })}
                                </label>
                                <p>$ {this.state.order.shipping.toFixed(2)}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.COMMISSION_LABEL',
                                    })}
                                </label>
                                {this.state.order.fees.map((fee) => (
                                    <p>
                                        {fee.name} = {fee.amount}
                                    </p>
                                ))}
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.TOTAL_LABEL',
                                    })}
                                </label>
                                <p>$ {this.state.order.total.toFixed(2)}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.PAYABLE_LABEL',
                                    })}
                                </label>
                                <p>$ {this.state.order.payable.toFixed(2)}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.CREATED_AT_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.order.createdAt &&
                                        new Date(
                                            this.state.order.createdAt,
                                        ).toLocaleDateString()}
                                </p>
                            </Col>
                            {this.state.order.payedAt && (
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'ORDERS.PAYED_AT_LABEL',
                                        })}
                                    </label>
                                    <p>
                                        {new Date(
                                            this.state.order.payedAt,
                                        ).toLocaleDateString()}
                                    </p>
                                </Col>
                            )}
                            {this.state.order.payedReference && (
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'ORDERS.PAYED_REFERENCE_LABEL',
                                        })}
                                    </label>
                                    <p>{this.state.order.payedReference}</p>
                                </Col>
                            )}
                        </Row>
                    </Card.Body>
                </Card>
                {this.state.order.payments.length > 0 && (
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ORDERS.PAYMENTS_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            {this.state.order.payments.map((payment, i) => (
                                <Row key={i}>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'ORDERS.PAYMENT_ID_LABEL',
                                            })}
                                        </label>
                                        <p>{payment.paymentId}</p>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.PAYMENT_STATUS_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id: `ORDERS.PAYMENT_STATUS_${payment.status.toUpperCase()}`,
                                            })}
                                        </p>
                                    </Col>
                                    <Col
                                        sm={6}
                                        className={
                                            i + 1 !==
                                            this.state.order.payments.length
                                                ? 'mb-4'
                                                : ''
                                        }
                                    >
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.PAYMENT_AMOUNT_LABEL',
                                            })}
                                        </label>
                                        <p>$ {payment.amount.toFixed(2)}</p>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                )}
                {this.state.order.shipments.length > 0 && (
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ORDERS.SHIPMENTS_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            {this.state.order.shipments.map((shipment, i) => (
                                <Row key={i}>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.SHIPMENT_DEPARTURE_LABEL',
                                            })}
                                        </label>
                                        <p>{shipment.departure}</p>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.SHIPMENT_DESTINATION_LABEL',
                                            })}
                                        </label>
                                        <p>{shipment.destination}</p>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.SHIPMENT_CARRIER_NAME_LABEL',
                                            })}
                                        </label>
                                        <p>{shipment.carrierName}</p>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.SHIPMENT_ESTIMATED_DELIVERY_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {new Date(
                                                shipment.estimatedDelivery,
                                            ).toLocaleDateString()}
                                        </p>
                                    </Col>
                                    <Col
                                        sm={6}
                                        className={
                                            i + 1 !==
                                            this.state.order.shipments.length
                                                ? 'mb-4'
                                                : ''
                                        }
                                    >
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.SHIPMENT_STATUS_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.props.intl.formatMessage({
                                                id: `ORDERS.SHIPMENT_STATUS_${shipment.status.toUpperCase()}`,
                                            })}
                                        </p>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                )}
                {this.state.order.details.length > 0 && (
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ORDERS.DETAILS_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            {this.state.order.details.map((detail, i) => (
                                <Row key={i}>
                                    <Col sm={6} className='mb-4'>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.DETAILS_PRODUCT_IMAGE_LABEL',
                                            })}
                                        </label>
                                        <div>
                                            <img
                                                hidden={!detail.pictureUrl}
                                                className='img-fluid'
                                                src={detail.pictureUrl}
                                                alt={`Product`}
                                                width='100px'
                                            />
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.DETAILS_PRODUCT_NAME_LABEL',
                                            })}
                                        </label>
                                        <p>{detail.name}</p>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.DETAILS_PRODUCT_PRICE_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            ${' '}
                                            {parseFloat(detail.price).toFixed(
                                                2,
                                            )}
                                        </p>
                                    </Col>
                                    <Col
                                        sm={6}
                                        className={
                                            i + 1 !==
                                            this.state.order.details.length
                                                ? 'mb-4'
                                                : ''
                                        }
                                    >
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.DETAILS_POST_ID_LABEL',
                                            })}
                                        </label>
                                        <p>{detail.postId}</p>
                                    </Col>
                                </Row>
                            ))}
                        </Card.Body>
                    </Card>
                )}
                {this.state.order.review &&
                    Object.keys(this.state.order.review).length > 0 && (
                        <Card className='mb-4'>
                            <Card.Header className='d-flex justify-content-between align-items-center'>
                                {this.props.intl.formatMessage({
                                    id:
                                        'ORDERS.REVIEW_INFORMATION_FORM_SECTION_TITLE',
                                })}
                                <Can run='ORDERS_EDIT'>
                                    <Button
                                        className='btn-danger btn-bold mx-2 btn'
                                        disabled={!this.state.order.review}
                                        onClick={this.toggleDeleteReviewModal}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: 'ORDERS.DELETE_REVIEW',
                                        })}
                                    </Button>
                                </Can>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.REVIEW_PRODUCT_RATE_AVERAGE_LABEL',
                                            })}
                                        </label>
                                        <div style={{marginBottom: '13px'}}>
                                            {this.state.order.review.rate && (
                                                <Rater
                                                    total={5}
                                                    rating={
                                                        this.state.order.review
                                                            .rate
                                                    }
                                                    interactive={false}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.REVIEW_PRODUCT_RATE_1_LABEL',
                                            })}
                                        </label>
                                        <div style={{marginBottom: '13px'}}>
                                            {this.state.order.review.rate1 && (
                                                <Rater
                                                    total={5}
                                                    rating={
                                                        this.state.order.review
                                                            .rate1
                                                    }
                                                    interactive={false}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.REVIEW_PRODUCT_RATE_2_LABEL',
                                            })}
                                        </label>
                                        <div style={{marginBottom: '13px'}}>
                                            {this.state.order.review.rate2 && (
                                                <Rater
                                                    total={5}
                                                    rating={
                                                        this.state.order.review
                                                            .rate2
                                                    }
                                                    interactive={false}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.REVIEW_PRODUCT_RATE_3_LABEL',
                                            })}
                                        </label>
                                        <div style={{marginBottom: '13px'}}>
                                            {this.state.order.review.rate3 && (
                                                <Rater
                                                    total={5}
                                                    rating={
                                                        this.state.order.review
                                                            .rate3
                                                    }
                                                    interactive={false}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'ORDERS.REVIEW_PRODUCT_COMMENTS_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.order.review.comment}</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )}
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'ORDERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchOrder}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.isSellerPaymentModalOpen}
                    onHide={this.togglePaymentModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.PAY_SELLER',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.SELLER_NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.order.sellerFullName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.SUBTOTAL_LABEL',
                                    })}
                                </label>
                                <p>$ {this.state.order.subtotal.toFixed(2)}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.COMMISSION_LABEL',
                                    })}
                                </label>
                                {this.state.order.fees.map((fee) => (
                                    <p>
                                        {fee.name} = {fee.amount}
                                    </p>
                                ))}
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.PAYABLE_LABEL',
                                    })}
                                </label>
                                <p>$ {this.state.order.payable.toFixed(2)}</p>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ORDERS.BANK_ACCOUNT_BANK_LABEL',
                                    })}
                                </label>
                                <p>{this.state.bankAccount.bank}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'ORDERS.BANK_ACCOUNT_ACCOUNT_NUMBER_LABEL',
                                    })}
                                </label>
                                <p>{this.state.bankAccount.accountNumber}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'ORDERS.BANK_ACCOUNT_STANDARIZED_NUMBER_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.bankAccount.standarizedNumber}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id:
                                            'ORDERS.BANK_ACCOUNT_HOLDER_NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.bankAccount.holderName}</p>
                            </Col>
                        </Row>
                        <hr />
                        <PaymentForm
                            togglePaymentModal={this.togglePaymentModal}
                            loading={this.state.loading}
                            handleSubmit={this.onSubmit}
                        />
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.isDeleteReviewModalOpen}
                    onHide={this.toggleDeleteReviewModal}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.DELETE_REVIEW',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'ORDERS.DELETE_REVIEW_CONFIRM_DELETE',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            type='button'
                            className='btn-bold mx-2 btn btn-dark btn-light-dark'
                            onClick={this.toggleDeleteReviewModal}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.DELETE_REVIEW_CANCEL',
                            })}
                        </Button>
                        <LoadingButton
                            type='submit'
                            variant='danger'
                            className='btn-bold'
                            onClick={this.deleteReview}
                            loading={this.loading}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ORDERS.DELETE_REVIEW_SUBMIT_DELETE',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(OrderDetail);
