import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink, Redirect} from 'react-router-dom';

import Return from '../../common/models/Return';
import API from '../../common/utils/API';
import Button from 'react-bootstrap/Button';
import ConfirmForm from '../../components/form/returns/ConfirmForm';
import Card from 'react-bootstrap/Card';
import {ReturnStatus} from '../../common/config/constants';

export class ReturnConfirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldRedirectToDetail: false,
            redirectSuccessMessage: '',
            isLoadErrorModalOpen: false,
            loading: false,
            returnOrder: new Return(),
        };
    }

    componentDidMount() {
        return this.fetchReturn();
    }

    fetchReturn = async () => {
        this.setState({loading: true});

        try {
            const {returnOrder} = await API.returns.detail(
                this.props.match.params.id,
            );

            if (returnOrder.status !== ReturnStatus.PENDING) {
                this.setState({
                    shouldRedirectToDetail: true,
                });
            } else {
                this.setState({
                    returnOrder,
                    loading: false,
                    isLoadErrorModalOpen: false,
                });
            }
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({
            loading: true,
        });

        try {
            input = input.returnDetails.map((detail) => {
                delete detail.index;

                return detail;
            });

            await API.returns.confirm(
                {returnDetails: input},
                this.props.match.params.id,
            );

            this.setState({
                shouldRedirectToDetail: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'RETURNS.DETAILS_SUCCESSFULLY_CONFIRMED',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
                shouldRedirectToDetail: false,
            });
        }
    };

    render() {
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                to={{
                    pathname: `/returns/${this.props.match.params.id}`,
                    state: this.state.redirectSuccessMessage,
                }}
            />
        ) : (
            <>
                <div className={'mb-4 d-flex justify-content-end'}>
                    <Button
                        as={NavLink}
                        to={`/returns/${this.props.match.params.id}`}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'GENERAL.CANCEL',
                        })}
                    </Button>
                </div>
                <Card className='mb-4' data-test='returnConfirmComponent'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'RETURNS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <ConfirmForm
                            handleSubmit={this.onSubmit}
                            details={this.state.returnOrder.details}
                        />
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default injectIntl(ReturnConfirm);
