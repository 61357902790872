import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import BaseForm from 'react-bootstrap/Form';
import {Formik} from 'formik';
import * as Yup from 'yup';

import Input from '../Input';
import {useIntl} from 'react-intl';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';

export default function Form(props) {
    const intl = useIntl();
    const validationSchema = Yup.object({
        name: Yup.string().required(
            intl.formatMessage({
                id: 'BRANDS.VALIDATION.NAME_MISSING',
            }),
        ),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const getInitialValues = () => {
        return {
            name: props.brand?.name || '',
        };
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'BRANDS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='12'>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id: 'BRANDS.NAME_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'BRANDS.BRAND_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='name'
                                                value={values.name}
                                                onChange={handleChange}
                                                error={errors.name}
                                                isInvalid={errors.name}
                                            />
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>
                    <div className='d-flex justify-content-between'>
                        {props.handleDeleteBrand ? (
                            <div>
                                <ConfirmModal
                                    titleModal={intl.formatMessage({
                                        id: 'BRANDS.BRAND_DELETE_MODAL_TITLE',
                                    })}
                                    handleConfirm={props.handleDeleteBrand}
                                    handleShow={() =>
                                        props.toggleDeleteBrandModal(true)
                                    }
                                    handleClose={() =>
                                        props.toggleDeleteBrandModal(false)
                                    }
                                    show={props.isDeleteBrandModalOpen}
                                    variant='danger'
                                    buttonClassName='btn-bold'
                                    buttonLabel={intl.formatMessage({
                                        id: 'BRANDS.BRAND_DELETE_BUTTON_LABEL',
                                    })}
                                    buttonAcceptLabel={intl.formatMessage({
                                        id:
                                            'BRANDS.BRAND_CONFIRM_DELETE_BUTTON_LABEL',
                                    })}
                                    buttonCloseLabel={intl.formatMessage({
                                        id:
                                            'BRANDS.BRAND_CANCEL_DELETE_BUTTON_LABEL',
                                    })}
                                >
                                    <p>
                                        {intl.formatMessage({
                                            id:
                                                'BRANDS.BRAND_DELETE_CONFIRMATION_MESSAGE',
                                        })}
                                    </p>
                                </ConfirmModal>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'BRANDS.BRAND_SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
