import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {createBrowserHistory} from 'history';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();
let root = document.getElementById('root');
if (root) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
    // eslint-disable-next-line no-restricted-globals
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    if (path) {
        history.replace(path);
    }

    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        root,
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
