import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Order from '../../models/Order';

const orders = {
    get: async (
        page = 1,
        search = '',
        status = '',
        shipmentStatus = '',
        userId = '',
        sellerId = '',
        startsAt = '',
        endsAt = '',
    ) => {
        const {data: response} = await API.get(
            `/orders/?page=${page}&search=${search}&orderStatus=${status}&shipmentStatus=${shipmentStatus}&userId=${userId}&sellerId=${sellerId}&startsAt=${startsAt}&endsAt=${endsAt}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((i) => new Order(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/orders/${id}`);

        return {
            order: new Order(response.data),
        };
    },
    payToSeller: async (input, id) => {
        const {data: response} = await API.post(
            `/orders/${id}/pay-to-seller`,
            input,
        );

        return {
            order: new Order(response.data),
        };
    },
    save: async (input) => {
        const {data: response} = await API.post('/orders', input);
        return {
            order: new Order(response.data),
        };
    },
    update: async (input, id) => {
        const {data: response} = await API.put(`/orders/${id}`, input);
        return {
            order: new Order(response.data),
        };
    },
    delete: async (id) => {
        await API.delete(`/orders/${id}`);
        return {};
    },
    deleteReview: async (id) => {
        const {data: response} = await API.delete(`/orders/${id}/rate`);

        return {
            order: new Order(response.data),
        };
    },
};

export default orders;
