import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import OrderList from './OrderList';
import OrderDetail from './OrderDetail';

function Orders() {
    const intl = useIntl();
    return (
        <Switch data-test='ordersComponent'>
            <PrivateRoute
                permission='ORDERS_INDEX'
                exact
                path='/orders'
                component={withLayout(
                    OrderList,
                    intl.formatMessage({id: 'ROUTES.ORDERS.INDEX'}),
                )}
            />

            <PrivateRoute
                permission='ORDERS_SHOW'
                exact
                path='/orders/:id'
                component={withLayout(
                    OrderDetail,
                    intl.formatMessage({id: 'ROUTES.ORDERS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Orders;
