export default class Permission {
    group = '';
    code = '';
    description = '';

    constructor(code = '', description = '', group = '') {
        this.code = code;
        this.group = group;
        this.description = description;
    }
}
