import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import UserList from './UserList';
import CreateUser from './CreateUser';
import UserDetail from './UserDetail';
import EditUser from './EditUser';

import withLayout from '../../components/ui/Layout';

function Users() {
    const intl = useIntl();

    return (
        <Switch>
            <PrivateRoute
                permission='USERS_INDEX'
                exact
                path='/users'
                component={withLayout(
                    UserList,
                    intl.formatMessage({id: 'ROUTES.USERS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='USERS_CREATE'
                path='/users/create'
                component={withLayout(
                    CreateUser,
                    intl.formatMessage({id: 'ROUTES.USERS.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='USERS_SHOW'
                exact
                path='/users/:id'
                component={withLayout(
                    UserDetail,
                    intl.formatMessage({id: 'ROUTES.USERS.SHOW'}),
                )}
            />
            <PrivateRoute
                permission='USERS_EDIT'
                exact
                path='/users/:id/edit'
                component={withLayout(
                    EditUser,
                    intl.formatMessage({id: 'ROUTES.USERS.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default Users;
