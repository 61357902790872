import React from 'react';
import BaseForm from 'react-bootstrap/Form';
import {Formik} from 'formik';
import * as Yup from 'yup';

import Input from '../Input';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/Button';
import LoadingButton from '../LoadingButton';

export default function PaymentForm(props) {
    const intl = useIntl();
    const validationSchema = Yup.object({
        reference: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'ORDERS.VALIDATION.REFERENCE_MISSING',
                }),
            )
            .min(
                8,
                intl.formatMessage({
                    id: 'ORDERS.VALIDATION.REFERENCE_MIN',
                }),
            )
            .max(
                26,
                intl.formatMessage({
                    id: 'ORDERS.VALIDATION.REFERENCE_MAX',
                }),
            ),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const getInitialValues = () => {
        return {
            reference: props.reference || '',
        };
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <BaseForm.Row>
                            <Input
                                sm='12'
                                type='text'
                                label={intl.formatMessage({
                                    id: 'ORDERS.REFERENCE_NUMBER_LABEL',
                                })}
                                placeholder={intl.formatMessage({
                                    id:
                                        'ORDERS.REFERENCE_NUMBER_FIELD_PLACEHOLDER',
                                })}
                                name='reference'
                                value={values.reference}
                                onChange={handleChange}
                                error={errors.reference}
                                isInvalid={errors.reference}
                            />
                        </BaseForm.Row>
                        <BaseForm.Row className='justify-content-sm-end'>
                            <Button
                                type='button'
                                className='btn-bold mx-2 btn btn-dark btn-light-dark'
                                onClick={props.togglePaymentModal}
                            >
                                {intl.formatMessage({
                                    id: 'ORDERS.PAY_CANCEL',
                                })}
                            </Button>
                            <LoadingButton
                                type='submit'
                                variant='success'
                                className='btn-bold'
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'ORDERS.PAY_BUTTON',
                                })}
                            </LoadingButton>
                        </BaseForm.Row>
                    </BaseForm>
                </>
            )}
        </Formik>
    );
}
