import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import PostList from './PostList';
import PostDetail from './PostDetail';
import PostEdit from './PostEdit';

function Roles() {
    const intl = useIntl();
    return (
        <Switch>
            <PrivateRoute
                permission='POSTS_INDEX'
                exact
                path='/posts'
                component={withLayout(
                    PostList,
                    intl.formatMessage({id: 'ROUTES.POSTS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='POSTS_SHOW'
                exact
                path='/posts/:id'
                component={withLayout(
                    PostDetail,
                    intl.formatMessage({id: 'ROUTES.POSTS.SHOW'}),
                )}
            />
            <PrivateRoute
                permission='POSTS_EDIT'
                exact
                path='/posts/:id/edit'
                component={withLayout(
                    PostEdit,
                    intl.formatMessage({id: 'ROUTES.POSTS.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default Roles;
