export default class Category {
    constructor({
        id = 0,
        name = '',
        names = {},
        bgColor = '',
        fgColor = '',
        width = 0,
        height = 0,
        length = 0,
        weight = 0,
        iconUrl = '',
        attributes = [],
    } = {}) {
        this.id = id;
        this.name = name;
        this.names = names;
        this.bgColor = bgColor;
        this.fgColor = fgColor;
        this.width = width;
        this.height = height;
        this.length = length;
        this.weight = weight;
        this.iconUrl = iconUrl;
        this.attributes = attributes;
    }
}
