import {API} from '../API';
import Permission from '../../models/Permission';

const permissions = {
    get: () =>
        new Promise((resolve, reject) => {
            return API.get(`/permissions`)
                .then((response) => {
                    let permissions = response.data.data.map(
                        (item) =>
                            new Permission(
                                item.code,
                                item.description,
                                item.group,
                            ),
                    );
                    resolve(permissions);
                })
                .catch((err) => reject(err));
        }),
};

export default permissions;
