import {API} from '../API';
import Fee from '../../models/Fee';

const fees = {
    all: async () => {
        const {data: response} = await API.get('fees/all');

        const fees = response.data.map((i) => new Fee(i));

        return fees;
    },
    update: async (input, id) => {
        const {data: response} = await API.put(`/fees/${id}`, input);
        return new Fee(response.data);
    },
};

export default fees;
