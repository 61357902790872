import React from 'react';
import StarterList from '../../components/starterList/StarterList';
import API from '../../common/utils/API';
import {injectIntl} from 'react-intl';
import {ReportStatus} from '../../common/config/constants';

class ComplainList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            complains: [],
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            search: '',
            status: '',
        };
    }

    componentDidMount() {
        return this.fetchBrand();
    }

    fetchBrand = async (page = 1) => {
        const result = await API.complains.get(
            page,
            this.state.search,
            this.state.status,
        );

        this.setState({
            complains: result.items,
            page: result.page,
            pageSize: result.pageSize,
            pageCount: result.pageCount,
            total: result.total,
        });
    };

    onPageChange = (page) => {
        return this.fetchBrand(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchBrand(1);
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                return this.fetchBrand(1);
            },
        );
    };

    render() {
        return (
            <>
                <StarterList
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            this.props.intl.formatMessage({
                                id: 'COMPLAINS.COMPLAIN_POST_ID_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COMPLAINS.COMPLAIN_POST_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COMPLAINS.COMPLAIN_REPORTER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COMPLAINS.COMPLAIN_SALESMAN_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COMPLAINS.COMPLAIN_REASON_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COMPLAINS.COMPLAIN_STATUS_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'COMPLAINS.COMPLAIN_DATE_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.complains.map((complain) => ({
                            content: [
                                complain.post.id,
                                complain.post.name,
                                `${complain.reporter.firstName} ${complain.reporter.lastName}`,
                                `${complain.post.userName}`,
                                complain.reason
                                    ? this.props.intl.formatMessage({
                                          id: `COMPLAINS.REASON_${complain.reason.toUpperCase()}`,
                                      })
                                    : '',
                                complain.status
                                    ? this.props.intl.formatMessage({
                                          id: `COMPLAINS.STATUS_${complain.status.toUpperCase()}`,
                                      })
                                    : '',
                                new Date(
                                    complain.createdAt,
                                ).toLocaleDateString(),
                            ],
                            link: `/complains/${complain.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            name: 'status',
                            value: this.state.status,
                            placeholder: this.props.intl.formatMessage({
                                id: 'COMPLAINS.STATUS_PLACEHOLDER',
                            }),
                            options: Object.values(ReportStatus).map(
                                (value) => ({
                                    value,
                                    label: this.props.intl.formatMessage({
                                        id: `COMPLAINS.STATUS_${value.toUpperCase()}`,
                                    }),
                                }),
                            ),
                        },
                    ]}
                />
            </>
        );
    }
}

export default injectIntl(ComplainList);
