import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Post from '../../models/Post';

const posts = {
    get: async (page = 1, search = '', status = '') => {
        const {data: response} = await API.get(
            `/posts?page=${page}&search=${search}&status=${status}`,
        );
        const list = new PaginatedList();
        list.items = response.data.map((i) => new Post(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;
        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/posts/${id}`);
        return {
            post: new Post(response.data),
        };
    },
    comments: async (id) => {
        const {data: response} = await API.get(`/post-questions?postId=${id}`);
        return {
            comments: response.data,
        };
    },
    save: async (input) => {
        const {data: response} = await API.post('/posts', input);
        return {
            post: new Post(response.data),
        };
    },
    update: async (id, input) => {
        const {data: response} = await API.put(`/posts/${id}`, input);
        return {
            post: new Post(response.data),
        };
    },
    review: async (id, status, comments) => {
        const {data: response} = await API.post(`/posts/${id}/review`, {
            status,
            comments,
        });
        return {
            post: new Post(response.data),
        };
    },
    toggle: async (id) => {
        const {data: response} = await API.put(`posts/${id}/toggle-post`);

        return {
            isActive: response.data.isActive,
        };
    },
    delete: async (id) => {
        await API.delete(`/posts/${id}`);
        return {};
    },
    brands: async () => {
        const {data: response} = await API.get(`/brands`);
        return {
            brands: response.data,
        };
    },
    categories: async () => {
        const {data: response} = await API.get(`/categories`);
        return {
            categories: response.data,
        };
    },
    attributes: async () => {
        const {data: response} = await API.get(`/attributes`);
        return {
            attributes: response.data,
        };
    },
};

export default posts;
