import React from 'react';
import ColumnContainer from './ColumnContainer';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function PasswordResetLinkSentContainer() {
    return (
        <ColumnContainer>
            <Form>
                <Form.Group>
                    <h3>Password Reset Link</h3>
                    <hr />
                    <p>
                        A password reset link was successfully sent to your
                        email address. Please follow the instructions provided
                        to reset your account password.
                    </p>
                </Form.Group>

                <Button variant='secondary' size='lg' block href='/login'>
                    Back to Login
                </Button>
            </Form>
        </ColumnContainer>
    );
}

export default PasswordResetLinkSentContainer;
