import React, {useEffect, useState} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import BaseForm from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {Formik} from 'formik';
import * as Yup from 'yup';

import ColorPicker from '../ColorPicker';
import CustomDropzone from '../Dropzone';
import Input from '../Input';
import {useIntl} from 'react-intl';
import LoadingButton from '../LoadingButton';
import ConfirmModal from '../ConfirmModal';

export default function Form(props) {
    const [attributes, setAttributes] = useState([]);
    const intl = useIntl();
    const hasIcon = !!props.category?.iconUrl;
    const validationSchema = Yup.object({
        nameEs: Yup.string().required(
            intl.formatMessage({
                id: 'CATEGORIES.VALIDATION.NAME_MISSING',
            }),
        ),
        nameEn: Yup.string().required(
            intl.formatMessage({
                id: 'CATEGORIES.VALIDATION.NAME_MISSING',
            }),
        ),
        bgColor: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.BGCOLOR_MISSING',
                }),
            )
            .matches(
                /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.COLOR_FORMAT',
                }),
            ),
        fgColor: Yup.string()
            .required(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.FGCOLOR_MISSING',
                }),
            )
            .matches(
                /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.COLOR_FORMAT',
                }),
            ),
        width: Yup.number()
            .required(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.WIDTH_MISSING',
                }),
            )
            .typeError(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.NUMBER_FORMAT',
                }),
            ),
        height: Yup.number()
            .required(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.HEIGHT_MISSING',
                }),
            )
            .typeError(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.NUMBER_FORMAT',
                }),
            ),
        length: Yup.number()
            .required(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.LENGTH_MISSING',
                }),
            )
            .typeError(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.NUMBER_FORMAT',
                }),
            ),
        weight: Yup.number()
            .required(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.WEIGHT_MISSING',
                }),
            )
            .typeError(
                intl.formatMessage({
                    id: 'CATEGORIES.VALIDATION.NUMBER_FORMAT',
                }),
            ),
        icon: hasIcon
            ? Yup.array()
            : Yup.array().required(
                  intl.formatMessage({
                      id: 'CATEGORIES.VALIDATION.ICON_MISSING',
                  }),
              ),
        attributes: Yup.array(),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const handleToggleAttributes = (e) => {
        const newAttributes = [...attributes];
        if (newAttributes.includes(+`${e.target.value}`)) {
            let index = newAttributes.indexOf(+`${e.target.value}`);
            if (index > -1) {
                newAttributes.splice(index, 1);
            }
        } else {
            newAttributes.push(+`${e.target.value}`);
        }

        setAttributes(newAttributes);

        return newAttributes;
    };

    const getInitialValues = () => {
        return {
            nameEs: props.category?.names.es || '',
            nameEn: props.category?.names.en || '',
            bgColor: props.category?.bgColor
                ? `#${props.category.bgColor}`
                : '',
            fgColor: props.category?.fgColor
                ? `#${props.category.fgColor}`
                : '',
            width: props.category?.width || '',
            height: props.category?.height || '',
            length: props.category?.length || '',
            weight: props.category?.weight || '',
            attributes:
                props.category?.attributes.map((attribute) => attribute.id) ||
                [],
        };
    };

    useEffect(() => {
        if (props.category?.attributes) {
            setAttributes(
                props.category?.attributes.map((attribute) => attribute.id),
            );
        }
    }, [props.category]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'CATEGORIES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='12'>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.NAME_ES_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='nameEs'
                                                value={values.nameEs}
                                                onChange={handleChange}
                                                error={errors.nameEs}
                                                isInvalid={errors.nameEs}
                                            />

                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.NAME_EN_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_NAME_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='nameEn'
                                                value={values.nameEn}
                                                onChange={handleChange}
                                                error={errors.nameEn}
                                                isInvalid={errors.nameEn}
                                            />

                                            <ColorPicker
                                                md='6'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.BGCOLOR_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_COLOR_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                color={values.bgColor}
                                                onChange={(bgColor) => {
                                                    values.bgColor = bgColor;
                                                }}
                                                error={errors.bgColor}
                                                bgColor={values.bgColor}
                                            />

                                            <ColorPicker
                                                md='6'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.FGCOLOR_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_COLOR_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                color={values.fgColor}
                                                onChange={(fgColor) => {
                                                    values.fgColor = fgColor;
                                                }}
                                                error={errors.fgColor}
                                                fgColor={values.fgColor}
                                            />

                                            <CustomDropzone
                                                md='6'
                                                dropPlaceholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_IMAGE_DROP_PLACEHOLDER',
                                                    },
                                                )}
                                                dragPlacegolder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_IMAGE_DRAG_PLACEHOLDER',
                                                    },
                                                )}
                                                fileImageAlt='fileImageAlt'
                                                file={[]}
                                                onChange={(file) => {
                                                    values.icon = file;
                                                }}
                                                error={errors.icon}
                                                iconUrl={
                                                    props.category?.iconUrl ||
                                                    ''
                                                }
                                            />

                                            <BaseForm.Group as={Col} md='6'>
                                                <BaseForm.Group as={Row}>
                                                    <BaseForm.Group
                                                        as={Col}
                                                        md='12'
                                                    >
                                                        <BaseForm.Label>
                                                            {intl.formatMessage(
                                                                {
                                                                    id:
                                                                        'CATEGORIES.ATTRIBUTES_LABEL',
                                                                },
                                                            )}
                                                        </BaseForm.Label>
                                                    </BaseForm.Group>
                                                    {props.attributes.map(
                                                        (attribute, i) => (
                                                            <Input
                                                                key={i}
                                                                md='3'
                                                                type='switch'
                                                                value={
                                                                    attribute.id
                                                                }
                                                                id={
                                                                    '#categoryAttribute' +
                                                                    i
                                                                }
                                                                placeholder={
                                                                    attribute.name
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    values.attributes = handleToggleAttributes(
                                                                        e,
                                                                    );
                                                                }}
                                                                checked={attributes.includes(
                                                                    attribute.id,
                                                                )}
                                                            />
                                                        ),
                                                    ) || null}
                                                </BaseForm.Group>
                                            </BaseForm.Group>
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>

                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'CATEGORIES.PIERCING_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md='12'>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.WIDTH_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_WIDTH_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='width'
                                                value={values.width}
                                                onChange={handleChange}
                                                error={errors.width}
                                                isInvalid={errors.width}
                                            />

                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.HEIGHT_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_HEIGHT_TYPE_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='height'
                                                value={values.height}
                                                onChange={handleChange}
                                                error={errors.height}
                                                isInvalid={errors.height}
                                            />

                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.LENGTH_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_LENGTH_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='length'
                                                value={values.length}
                                                onChange={handleChange}
                                                error={errors.length}
                                                isInvalid={errors.length}
                                            />

                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id:
                                                        'CATEGORIES.WEIGHT_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'CATEGORIES.CATEGORY_WEIGHT_TYPE_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='weight'
                                                value={values.weight}
                                                onChange={handleChange}
                                                error={errors.weight}
                                                isInvalid={errors.weight}
                                            />
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>
                    <div className='d-flex justify-content-between'>
                        {props.handleDeleteCategory ? (
                            <div>
                                <ConfirmModal
                                    titleModal={intl.formatMessage({
                                        id:
                                            'CATEGORIES.CATEGORY_DELETE_MODAL_TITLE',
                                    })}
                                    handleConfirm={props.handleDeleteCategory}
                                    handleShow={() =>
                                        props.toggleDeleteCategoryModal(true)
                                    }
                                    handleClose={() =>
                                        props.toggleDeleteCategoryModal(false)
                                    }
                                    show={props.isDeleteCategoryModalOpen}
                                    variant='danger'
                                    buttonClassName='btn-bold'
                                    buttonLabel={intl.formatMessage({
                                        id:
                                            'CATEGORIES.CATEGORY_DELETE_BUTTON_LABEL',
                                    })}
                                    buttonAcceptLabel={intl.formatMessage({
                                        id:
                                            'CATEGORIES.CATEGORY_CONFIRM_DELETE_BUTTON_LABEL',
                                    })}
                                    buttonCloseLabel={intl.formatMessage({
                                        id:
                                            'CATEGORIES.CATEGORY_CANCEL_DELETE_BUTTON_LABEL',
                                    })}
                                >
                                    <p>
                                        {intl.formatMessage({
                                            id:
                                                'CATEGORIES.CATEGORY_DELETE_CONFIRMATION_MESSAGE',
                                        })}
                                    </p>
                                </ConfirmModal>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'CATEGORIES.CATEGORY_SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
