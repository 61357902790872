import React from 'react';
import {Redirect, NavLink} from 'react-router-dom';

import API from '../../common/utils/API';

import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import {Col} from 'react-bootstrap';
import Input from '../../components/form/Input';
import LoadingButton from '../../components/form/LoadingButton';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import codes from 'country-calling-code';
import User from '../../common/models/User';
import ConfirmModal from '../../components/form/ConfirmModal';
import {injectIntl} from 'react-intl';
import Can from '../../common/security/Can';

class EditUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToDetail: false,
            shouldRedirectToIndex: false,
            user: new User(),
            error: '',
            errors: {},
            role: {},
            roles: [],
            isDeleteUserModalOpen: false,
            isLoadErrorModalOpen: false,
            redirectSuccessMessage: '',
            changePassword: false,
            hiddenPassword: true,
            hiddenPasswordConfirmation: true,
            fields: ['firstName', 'lastName', 'phoneCountry', 'phone', 'email'],
            pictureProfile: [],
        };
    }

    componentDidMount = () => {
        this.loadUser();
        this.loadRoles();
    };

    loadRoles = () => {
        API.roles
            .get(API.roles.constants.ALL)
            .then((response) => {
                this.setState({
                    roles: response.items,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadErrorModalOpen: true,
                });
            });
    };

    loadUser = () => {
        this.setState({
            loading: true,
        });
        API.users
            .detail(this.props.match.params.id)
            .then((response) => {
                response.user.roles = response.user.roles.map(
                    (role) => role.id,
                );
                this.setState({
                    user: response.user,
                    isLoadErrorModalOpen: false,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadErrorModalOpen: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleUserInput = (e) => {
        const user = this.state.user;
        const name = e.target.name;
        const value = e.target.value;
        user[name] = value;
        this.setState({user: user}, () => {
            this.validateField(name, value);
        });

        if ('password' === name) {
            this.validateField(
                'passwordConfirmation',
                user.passwordConfirmation,
            );
        }

        if ('passwordConfirmation' === name) {
            this.validateField('password', user.password);
        }
    };

    validateForm() {
        const user = this.state.user;
        let validFields = 0;
        this.state.fields.forEach((field) => {
            validFields += this.validateField(field, user[field]) ? 1 : 0;
        });
        return validFields === this.state.fields.length;
    }

    validateField(fieldName, value) {
        let errors = this.state.errors;
        let isValid = true;
        errors[fieldName] = '';

        switch (fieldName) {
            case 'firstName':
                if (!value || value.trim().length === 0) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.FIRST_NAME_MISSING',
                    });
                }
                break;
            case 'lastName':
                if (!value || value.trim().length === 0) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.LAST_NAME_MISSING',
                    });
                }
                break;
            case 'phone':
                if (!value || value.trim().length === 0) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.MOBILE_PHONE_MISSING',
                    });
                }
                break;
            case 'email':
                if (
                    !value ||
                    !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i)
                ) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.EMAIL_INVALID_FORMAT',
                    });
                }

                if (!value || value.trim().length === 0) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.EMAIL_MISSING',
                    });
                }

                break;
            case 'password':
                if (!value || value !== this.state.user.passwordConfirmation) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id:
                            'USERS.VALIDATION.PASSWORD_CONFIRMATION_DOES_NOT_MATCH',
                    });
                }

                if (!value || value.trim().length < 8) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.PASSWORD_MIN',
                    });
                }

                if (!value || value.trim().length === 0) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.PASSWORD_MISSING',
                    });
                }
                break;
            case 'passwordConfirmation':
                if (!value || value !== this.state.user.password) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id:
                            'USERS.VALIDATION.PASSWORD_CONFIRMATION_DOES_NOT_MATCH',
                    });
                }

                if (!value || value.trim().length < 8) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.PASSWORD_MIN',
                    });
                }

                if (!value || value.trim().length === 0) {
                    isValid = false;
                    errors[fieldName] = this.props.intl.formatMessage({
                        id: 'USERS.VALIDATION.PASSWORD_CONFIRMATION_MISSING',
                    });
                }
                break;
            default:
                break;
        }

        this.setState({errors});

        return isValid;
    }

    handleUpdateUserFormSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({
                loading: true,
            });
            let formData = new FormData();

            formData.append('firstName', this.state.user.firstName);
            formData.append('lastName', this.state.user.lastName);
            formData.append('email', this.state.user.email);
            formData.append('phoneCountry', this.state.user.phoneCountry);
            formData.append('phone', this.state.user.phone);

            if (this.state.changePassword) {
                formData.append('password', this.state.user.password);
            }

            for (var i = 0; i < this.state.user.roles.length; i++) {
                formData.append('roles[]', this.state.user.roles[i]);
            }

            if (this.state.pictureProfile.length) {
                formData.append('picture', this.state.pictureProfile[0]);
            }

            API.users
                .update(this.props.match.params.id, formData)
                .then((response) => {
                    this.setState({
                        shouldRedirectToDetail: true,
                        redirectSuccessMessage: this.props.intl.formatMessage({
                            id: 'USERS.USER_SUCCESSFULLY_UPDATED_MESSAGE',
                        }),
                    });
                })
                .catch((e) => {
                    if (e.response && e.response.status === 422) {
                        this.setState({
                            errors: e.response.data.messages,
                        });
                    }
                    this.setState({
                        error: {},
                        loading: false,
                    });
                });
        }
    };

    handleDeleteUser = (e) => {
        API.users
            .delete(this.props.match.params.id)
            .then((response) => {
                this.setState({
                    shouldRedirectToIndex: true,
                    redirectSuccessMessage: this.props.intl.formatMessage({
                        id: 'USERS.USER_SUCCESSFULLY_DELETED_MESSAGE',
                    }),
                });
            })
            .catch((e) => {
                this.setState({
                    isDeleteUserModalOpen: false,
                    error: {},
                    loading: false,
                });
            });
    };

    handleToggleChangePassword = () => {
        let fields = this.state.fields;
        if (fields.includes('password')) {
            var index = fields.indexOf('password');
            if (index > -1) {
                fields.splice(index, 1);
            }
            index = fields.indexOf('passwordConfirmation');
            if (index > -1) {
                fields.splice(index, 1);
            }
        } else {
            fields.push('password', 'passwordConfirmation');
        }

        this.setState({
            changePassword: !this.state.changePassword,
            fields: fields,
        });
    };

    handleTogglePassword = () => {
        this.setState({hiddenPassword: !this.state.hiddenPassword});
    };

    handleTogglePasswordConfirmation = () => {
        this.setState({
            hiddenPasswordConfirmation: !this.state.hiddenPasswordConfirmation,
        });
    };

    handleToggleRoles = (e) => {
        const user = this.state.user;
        if (user.roles.includes(parseInt(e.target.value))) {
            var index = user.roles.indexOf(parseInt(e.target.value));
            if (index > -1) {
                user.roles.splice(index, 1);
            }
        } else {
            user.roles.push(parseInt(e.target.value));
        }

        this.setState({user: user});
    };

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length) {
            let errors = this.state.errors;
            errors.push([
                this.props.intl.formatMessage({
                    id: 'USERS.DROPZONE_PICTURE_ERROR',
                }),
            ]);
            this.setState({
                errors: errors,
            });
        }

        this.setState({
            pictureProfile: acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            ),
        });
    };

    render() {
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                to={{
                    pathname: `/users/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/users',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'USERS.VALIDATION.GENERIC_ERROR',
                        })}
                    </p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to={`/users/${this.props.match.params.id}`}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'USERS.BACK_TO_USER_DETAIL',
                        })}
                    </Button>
                </div>
                <Form onSubmit={this.handleUpdateUserFormSubmit}>
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Col md='3'>
                                    <Dropzone
                                        accept='image/*'
                                        onDrop={this.onDrop}
                                        multiple='false'
                                    >
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                        }) => (
                                            <section>
                                                <div
                                                    {...getRootProps()}
                                                    className={classNames(
                                                        'dropzone',
                                                        {
                                                            'dropzone--isActive': isDragActive,
                                                        },
                                                    )}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    {!this.state.pictureProfile
                                                        .length ? (
                                                        isDragActive ? (
                                                            <p>
                                                                {this.props.intl.formatMessage(
                                                                    {
                                                                        id:
                                                                            'USERS.PROFILE_IMAGE_DRAG_PLACEHOLDER',
                                                                    },
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                {this.props.intl.formatMessage(
                                                                    {
                                                                        id:
                                                                            'USERS.PROFILE_IMAGE_DROP_PLACEHOLDER',
                                                                    },
                                                                )}
                                                            </p>
                                                        )
                                                    ) : null}

                                                    {this.state.pictureProfile
                                                        .length ? (
                                                        <Form.Row>
                                                            <Col>
                                                                {this.state.pictureProfile.map(
                                                                    (file) => (
                                                                        <img
                                                                            className='img-fluid'
                                                                            src={
                                                                                file.preview
                                                                            }
                                                                            alt={`${this.state.user.firstName} ${this.state.user.lastName}`}
                                                                        />
                                                                    ),
                                                                )}
                                                            </Col>
                                                        </Form.Row>
                                                    ) : null}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Col>
                                <Col md='9'>
                                    <Form.Row>
                                        <Input
                                            md='6'
                                            type='text'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.USER_FIRST_NAME_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.USER_FIRST_NAME_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            name='firstName'
                                            value={this.state.user.firstName}
                                            onChange={this.handleUserInput}
                                            error={this.state.errors.firstName}
                                            isInvalid={
                                                this.state.errors.firstName
                                            }
                                        />
                                        <Input
                                            md='6'
                                            type='text'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.USER_LAST_NAME_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.USER_FIRST_NAME_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            name='lastName'
                                            value={this.state.user.lastName}
                                            onChange={this.handleUserInput}
                                            error={this.state.errors.lastName}
                                            isInvalid={
                                                this.state.errors.lastName
                                            }
                                        />
                                    </Form.Row>
                                    <Form.Row>
                                        <Input
                                            md='6'
                                            type='select'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.MOBILE_PHONE_COUNTRY_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.MOBILE_PHONE_COUNTRY_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            name='phoneCountry'
                                            value={this.state.user.phoneCountry}
                                            onChange={this.handleUserInput}
                                            error={
                                                this.state.errors.phoneCountry
                                            }
                                            isInvalid={
                                                this.state.errors.phoneCountry
                                            }
                                            children={codes.map(
                                                ({country, isoCode2}) => (
                                                    <option value={isoCode2}>
                                                        {country}
                                                    </option>
                                                ),
                                            )}
                                        />
                                        <Input
                                            md='6'
                                            type='text'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.MOBILE_PHONE_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.MOBILE_PHONE_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            name='phone'
                                            value={this.state.user.phone}
                                            onChange={this.handleUserInput}
                                            error={this.state.errors.phone}
                                            isInvalid={this.state.errors.phone}
                                        />
                                        <Input
                                            md='6'
                                            type='email'
                                            label={this.props.intl.formatMessage(
                                                {id: 'USERS.EMAIL_FIELD_LABEL'},
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.EMAIL_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            name='email'
                                            value={this.state.user.email}
                                            onChange={this.handleUserInput}
                                            error={this.state.errors.email}
                                            isInvalid={this.state.errors.email}
                                        />
                                    </Form.Row>
                                    <Form.Row>
                                        <Input
                                            type='switch'
                                            id='changePassword'
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.USER_CHANGE_PASSWORD_FIELD_LABEL',
                                                },
                                            )}
                                            onChange={
                                                this.handleToggleChangePassword
                                            }
                                            checked={this.state.changePassword}
                                        ></Input>
                                    </Form.Row>
                                    <Form.Row
                                        hidden={!this.state.changePassword}
                                    >
                                        <Input
                                            md='6'
                                            type='password'
                                            name='password'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.PASSWORD_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.PASSWORD_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            value={this.state.user.password}
                                            onChange={this.handleUserInput}
                                            error={this.state.errors.password}
                                            isInvalid={
                                                this.state.errors.password
                                            }
                                            hiddenToggle={
                                                this.state.hiddenPassword
                                            }
                                            onClickToggle={
                                                this.handleTogglePassword
                                            }
                                        />
                                        <Input
                                            md='6'
                                            type='password'
                                            name='passwordConfirmation'
                                            label={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.CONFIRM_PASSWORD_FIELD_LABEL',
                                                },
                                            )}
                                            placeholder={this.props.intl.formatMessage(
                                                {
                                                    id:
                                                        'USERS.CONFIRM_PASSWORD_FIELD_PLACEHOLDER',
                                                },
                                            )}
                                            value={
                                                this.state.user
                                                    .passwordConfirmation
                                            }
                                            onChange={this.handleUserInput}
                                            error={
                                                this.state.errors
                                                    .passwordConfirmation
                                            }
                                            isInvalid={
                                                this.state.errors
                                                    .passwordConfirmation
                                            }
                                            hiddenToggle={
                                                this.state
                                                    .hiddenPasswordConfirmation
                                            }
                                            onClickToggle={
                                                this
                                                    .handleTogglePasswordConfirmation
                                            }
                                        />
                                    </Form.Row>
                                </Col>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ROLE_AND_PRIVILEGES_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: 'USERS.ROLE_FIELD_PLACEHOLDER',
                                    })}
                                </p>
                            </Form.Row>
                            <Form.Row>
                                {this.state.roles.map((role, key) => (
                                    <Input
                                        key={key}
                                        md='3'
                                        type='switch'
                                        value={role.id}
                                        id={'#role' + key}
                                        placeholder={role.name}
                                        onChange={this.handleToggleRoles}
                                        checked={this.state.user.roles.includes(
                                            role.id,
                                        )}
                                    />
                                ))}
                            </Form.Row>
                        </Card.Body>
                    </Card>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <Can run='USERS_DELETE'>
                                <ConfirmModal
                                    titleModal={this.props.intl.formatMessage({
                                        id: 'USERS.DELETE_USER_MODAL_TITLE',
                                    })}
                                    handleConfirm={this.handleDeleteUser}
                                    handleShow={() =>
                                        this.setState({
                                            isDeleteUserModalOpen: true,
                                        })
                                    }
                                    handleClose={() =>
                                        this.setState({
                                            isDeleteUserModalOpen: false,
                                        })
                                    }
                                    show={this.state.isDeleteUserModalOpen}
                                    variant='danger'
                                    buttonClassName='btn-bold'
                                    buttonLabel={this.props.intl.formatMessage({
                                        id: 'USERS.DELETE_USER_BUTTON_LABEL',
                                    })}
                                    buttonAcceptLabel={this.props.intl.formatMessage(
                                        {
                                            id:
                                                'USERS.CONFIRM_DELETE_BUTTON_LABEL',
                                        },
                                    )}
                                    buttonCloseLabel={this.props.intl.formatMessage(
                                        {
                                            id:
                                                'USERS.CANCEL_DELETE_BUTTON_LABEL',
                                        },
                                    )}
                                >
                                    <p>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'USERS.DELETE_USER_CONFIRMATION_MESSAGE',
                                        })}
                                    </p>
                                </ConfirmModal>
                            </Can>
                        </div>
                        <div>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                loading={this.state.loading}
                            >
                                {this.props.intl.formatMessage({
                                    id: 'USERS.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </Form>
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadUser}
                        >
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}
export default injectIntl(EditUser);
