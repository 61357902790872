import React from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useIntl} from 'react-intl';
import BaseForm from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import Input from '../Input';
import ConfirmModal from '../ConfirmModal';
import LoadingButton from '../LoadingButton';

export default function Form(props) {
    const intl = useIntl();
    const validationSchema = Yup.object({
        question: Yup.string().required(
            intl.formatMessage({
                id: 'FAQS.VALIDATION.QUESTION_MISSING',
            }),
        ),
        answer: Yup.string().required(
            intl.formatMessage({
                id: 'FAQS.VALIDATION.ANSWER_MISSING',
            }),
        ),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input);
    };

    const getInitialValues = () => ({
        question: props.faq?.question || '',
        answer: props.faq?.answer || '',
    });

    const handleClose = () => {
        props.toggleDeleteFaqModal(false);
    };

    const handleShow = () => {
        props.toggleDeleteFaqModal(true);
    };

    return (
        <Formik
            data-test='faqFormComponent'
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <BaseForm>
                        <Card className='mb-4'>
                            <Card.Header>
                                {intl.formatMessage({
                                    id:
                                        'FAQS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                                })}
                            </Card.Header>
                            <Card.Body>
                                <BaseForm.Row>
                                    <Col md={12}>
                                        <BaseForm.Row>
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id: 'FAQS.QUESTION_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'FAQS.FAQ_QUESTION_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='question'
                                                value={values.question}
                                                onChange={handleChange}
                                                error={errors.question}
                                                isInvalid={errors.question}
                                            />
                                            <Input
                                                md='6'
                                                type='text'
                                                label={intl.formatMessage({
                                                    id: 'FAQS.ANSWER_LABEL',
                                                })}
                                                placeholder={intl.formatMessage(
                                                    {
                                                        id:
                                                            'FAQS.FAQ_ANSWER_FIELD_PLACEHOLDER',
                                                    },
                                                )}
                                                name='answer'
                                                value={values.answer}
                                                onChange={handleChange}
                                                error={errors.answer}
                                                isInvalid={errors.answer}
                                            />
                                        </BaseForm.Row>
                                    </Col>
                                </BaseForm.Row>
                            </Card.Body>
                        </Card>
                    </BaseForm>
                    <div className='d-flex justify-content-between'>
                        {props.handleDeleteFaq ? (
                            <div>
                                <ConfirmModal
                                    titleModal={intl.formatMessage({
                                        id: 'FAQS.DELETE_MODAL_TITLE',
                                    })}
                                    handleConfirm={props.handleDeleteFaq}
                                    handleShow={handleShow}
                                    handleClose={handleClose}
                                    show={props.isDeleteFaqModalOpen}
                                    variant='danger'
                                    buttonClassName='btn-bold'
                                    buttonLabel={intl.formatMessage({
                                        id: 'FAQS.DELETE_BUTTON_LABEL',
                                    })}
                                    buttonAcceptLabel={intl.formatMessage({
                                        id: 'FAQS.CONFIRM_DELETE_BUTTON_LABEL',
                                    })}
                                    buttonCloseLabel={intl.formatMessage({
                                        id: 'FAQS.CANCEL_DELETE_BUTTON_LABEL',
                                    })}
                                >
                                    <p>
                                        {intl.formatMessage({
                                            id:
                                                'FAQS.DELETE_CONFIRMATION_MESSAGE',
                                        })}
                                    </p>
                                </ConfirmModal>
                            </div>
                        ) : (
                            <div></div>
                        )}
                        <div className='text-right'>
                            <LoadingButton
                                variant='primary'
                                className='btn-bold'
                                type='submit'
                                onClick={handleSubmit}
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'FAQS.SAVE_BUTTON_TEXT',
                                })}
                            </LoadingButton>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
}
