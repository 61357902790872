import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import ReturnList from './ReturnList';
import ReturnConfirm from './ReturnConfirm';
import ReturnDetail from './ReturnDetail';

function Returns() {
    const intl = useIntl();
    return (
        <Switch data-test='returnsComponent'>
            <PrivateRoute
                permission='RETURNS_INDEX'
                exact
                path='/returns'
                component={withLayout(
                    ReturnList,
                    intl.formatMessage({id: 'ROUTES.RETURNS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='RETURNS_SHOW'
                exact
                path='/returns/:id'
                component={withLayout(
                    ReturnDetail,
                    intl.formatMessage({id: 'ROUTES.RETURNS.SHOW'}),
                )}
            />
            <PrivateRoute
                permission='RETURNS_CONFIRM'
                exact
                path='/returns/:id/confirm'
                component={withLayout(
                    ReturnConfirm,
                    intl.formatMessage({id: 'ROUTES.RETURNS.CONFIRM'}),
                )}
            />
        </Switch>
    );
}

export default Returns;
