import {API} from '../API';
import BankAccount from '../../models/BankAccount';

const bankAccounts = {
    getByUser: async (userId) => {
        const {data: response} = await API.get(`/bank-accounts/${userId}`);

        return {
            bankAccount: new BankAccount(response.data),
        };
    },
};

export default bankAccounts;
