import React from 'react';
import StarterList from '../../components/starterList/StarterList';
import API from '../../common/utils/API';
import {injectIntl} from 'react-intl';

export class BrandList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brands: [],
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            search: '',
            status: '',
        };
    }

    componentDidMount() {
        return this.fetchBrands();
    }

    fetchBrands = async (page = 1) => {
        const result = await API.brands.get(page, this.state.search);

        this.setState({
            brands: result.items,
            page: result.page,
            pageSize: result.pageSize,
            pageCount: result.pageCount,
            total: result.total,
        });

        return result;
    };

    onPageChange = (page) => {
        return this.fetchBrands(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchBrands(1);
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                return this.fetchBrands(1);
            },
        );
    };

    render() {
        return (
            <>
                <StarterList
                    data-test='brandListComponent'
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'BRANDS.BRAND_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.brands.map((brand) => ({
                            content: [brand.id, brand.name],
                            link: `/brands/${brand.id}`,
                        })),
                    }}
                    addNewPermission={'BRANDS_CREATE'}
                    addNewLink={`brands/create`}
                />
            </>
        );
    }
}

export default injectIntl(BrandList);
