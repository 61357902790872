import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Complain from '../../models/Complain';

const complains = {
    get: async (page = 1, search = '', status = '') => {
        const {data: response} = await API.get(
            `/post-reports?page=${page}&search=${search}&status=${status}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((i) => new Complain(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/post-reports/${id}`);

        return {
            complain: new Complain(response.data),
        };
    },
    review: async (id, status, comments) => {
        const {data: response} = await API.post(`/post-reports/${id}/review`, {
            status,
            comments,
        });
        return {
            complain: new Complain(response.data),
        };
    },
};

export default complains;
