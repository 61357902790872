let defaultLang = window.navigator.language;

if (defaultLang.includes('-')) {
    defaultLang = defaultLang.split('-')[0];
}

if (!['en', 'es'].includes(defaultLang)) {
    defaultLang = 'es';
}

const initialState = {
    lang: defaultLang,
};

export function langReducer(state = initialState, action) {
    switch (action.type) {
        case 'SWITCH_LANGUAGE':
            return Object.assign({}, state, {
                lang: action.payload.lang,
            });
        default:
            return state;
    }
}
