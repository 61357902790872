import Post from './Post';

export default class Complain {
    constructor({
        id = 0,
        status = '',
        reason = '',
        text = '',
        post = {},
        reporter = {},
        reviewer = {},
        createdAt = '',
    } = {}) {
        this.id = id;
        this.status = status;
        this.reason = reason;
        this.text = text;
        this.post = new Post(post);
        this.reporter = reporter;
        this.reviewer = reviewer;
        this.createdAt = createdAt;
    }

    get reporterName() {
        return `${this.reporter.firstName || ''} ${
            this.reporter.lastName || ''
        }`.trim();
    }
    get reviewerName() {
        return `${this.reviewer.firstName || ''} ${
            this.reviewer.lastName || ''
        }`.trim();
    }

    get formatDate() {
        if (!this.createdAt) {
            return '';
        }
        return new Date(this.createdAt).toLocaleDateString();
    }

    get censoredPost() {
        return this.post.isCensored;
    }
}
