import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Row, Col} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import SwiperCore, {Navigation, Pagination} from 'swiper';

import API from '../../common/utils/API';
import Complain from '../../common/models/Complain';
import {ReportStatus} from '../../common/config/constants';
import Can from '../../common/security/Can';
import Input from '../../components/form/Input';

SwiperCore.use([Navigation, Pagination]);

class ComplainDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            complain: new Complain(),
            error: '',
            openApprove: false,
            openReject: false,
            reviewComments: '',
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchItem();
    }

    fetchItem = async () => {
        this.setState({loading: true});

        try {
            const {complain} = await API.complains.detail(
                this.props.match.params.id,
            );

            this.setState({
                complain,
                loading: false,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
            });
        }
    };

    reviewItem = async (status) => {
        this.setState({loading: true});
        try {
            const {complain} = await API.complains.review(
                this.props.match.params.id,
                status,
                this.state.reviewComments,
            );
            this.setState({
                complain,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
            });
        }
        this.setState({
            loading: false,
            openApprove: false,
            openReject: false,
            reviewComments: '',
        });
    };

    toggleModal = (modal, show = false) => {
        this.setState({
            [modal]: show,
            reviewComments: '',
        });
    };

    onChange = (event) => {
        this.setState({
            reviewComments: event.target.value,
        });
    };

    formatDate = (s) => {
        const date = new Date(s);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/complains'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'COMPLAINS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.POST_ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.complain.post.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.POST_NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.complain.post.name}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.SALESMAN_LABEL',
                                    })}
                                </label>
                                <p>{`${this.state.complain.post.userName}`}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.REPORTER_LABEL',
                                    })}
                                </label>
                                <p>{this.state.complain.reporterName}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.COMPLAIN_STATUS_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.complain.status
                                        ? this.props.intl.formatMessage({
                                              id: `COMPLAINS.STATUS_${this.state.complain.status.toUpperCase()}`,
                                          })
                                        : ''}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.REASON_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.state.complain.reason
                                        ? this.props.intl.formatMessage({
                                              id: `COMPLAINS.REASON_${this.state.complain.reason.toUpperCase()}`,
                                          })
                                        : ''}
                                </p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.COMMENTS_LABEL',
                                    })}
                                </label>
                                <p>{this.state.complain.text}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.POST_CENSORED_LABEL',
                                    })}
                                </label>
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: `COMPLAINS.${
                                            this.state.complain.censoredPost
                                                ? 'YES'
                                                : 'NO'
                                        }`,
                                    })}
                                </p>
                            </Col>
                            {ReportStatus.APPROVED ===
                                this.state.complain.status ||
                            ReportStatus.REJECTED ===
                                this.state.complain.status ? (
                                <Col sm={6}>
                                    <label className='field-title'>
                                        {this.props.intl.formatMessage({
                                            id: 'COMPLAINS.REVIEWER_LABEL',
                                        })}
                                    </label>
                                    <p>{this.state.complain.reviewerName}</p>
                                </Col>
                            ) : null}
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'COMPLAINS.POST_CREATED_LABEL',
                                    })}
                                </label>
                                <p>{this.state.complain.formatDate}</p>
                            </Col>
                            <Can run='REPORTS_EDIT'>
                                {ReportStatus.PENDING ===
                                this.state.complain.status ? (
                                    <Col sm={12} className='text-right'>
                                        <Button
                                            variant='success'
                                            className='ml-2 mb-2'
                                            onClick={() =>
                                                this.toggleModal(
                                                    'openApprove',
                                                    true,
                                                )
                                            }
                                        >
                                            {this.props.intl.formatMessage({
                                                id: 'COMPLAINS.ACTION_APPROVE',
                                            })}
                                        </Button>
                                        <Button
                                            variant='danger'
                                            className='ml-2 mb-2'
                                            onClick={() =>
                                                this.toggleModal(
                                                    'openReject',
                                                    true,
                                                )
                                            }
                                        >
                                            {this.props.intl.formatMessage({
                                                id: 'COMPLAINS.ACTION_REJECT',
                                            })}
                                        </Button>
                                    </Col>
                                ) : null}
                            </Can>
                        </Row>
                    </Card.Body>
                </Card>
                <Modal
                    show={this.state.openApprove}
                    onHide={() => this.toggleModal('openApprove', false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'COMPLAINS.ACTION_APPROVE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col md={12}>
                            {this.props.intl.formatMessage({
                                id: 'COMPLAINS.REVIEW_STATUS_APPROVED',
                            })}
                        </Col>
                        <Input
                            type='textarea'
                            label={this.props.intl.formatMessage({
                                id: 'COMPLAINS.REVIEW_COMMENTS_LABEL',
                            })}
                            value={this.state.reviewComments}
                            onChange={this.onChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='outline-secondary'
                            onClick={() =>
                                this.toggleModal('openApprove', false)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.CANCEL',
                            })}
                        </Button>
                        <Button
                            variant='success'
                            disabled={this.state.loading}
                            onClick={() =>
                                this.reviewItem(ReportStatus.APPROVED)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'COMPLAINS.ACTION_DO_APPROVE',
                            })}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={this.state.openReject}
                    onHide={() => this.toggleModal('openReject', false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'COMPLAINS.ACTION_REJECT',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col md={12}>
                            {this.props.intl.formatMessage({
                                id: 'COMPLAINS.REVIEW_STATUS_REJECTED',
                            })}
                        </Col>
                        <Input
                            type='textarea'
                            label={this.props.intl.formatMessage({
                                id: 'COMPLAINS.REVIEW_COMMENTS_LABEL',
                            })}
                            value={this.state.reviewComments}
                            onChange={this.onChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='outline-secondary'
                            onClick={() =>
                                this.toggleModal('openReject', false)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'GENERAL.CANCEL',
                            })}
                        </Button>
                        <Button
                            variant='danger'
                            disabled={this.state.loading}
                            onClick={() =>
                                this.reviewItem(ReportStatus.REJECTED)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'COMPLAINS.ACTION_DO_REJECT',
                            })}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(ComplainDetail);
