import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import OrderReportList from './OrderReportList';
import OrderReportDetail from './OrderReportDetail';

function OrdersReports() {
    const intl = useIntl();
    return (
        <Switch data-test='ordersReportsComponent'>
            <PrivateRoute
                permission='ORDERCASES_INDEX'
                exact
                path='/orders-reports'
                component={withLayout(
                    OrderReportList,
                    intl.formatMessage({id: 'ROUTES.ORDERS_REPORTS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='ORDERCASES_SHOW'
                exact
                path='/orders-reports/:id'
                component={withLayout(
                    OrderReportDetail,
                    intl.formatMessage({id: 'ROUTES.ORDERS_REPORTS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default OrdersReports;
