import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink, Redirect} from 'react-router-dom';

import Form from '../../components/form/brand/Form';
import API from '../../common/utils/API';
import Brand from '../../common/models/Brand';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LoadingButton from '../../components/form/LoadingButton';

export class EditBrand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldRedirectToIndex: false,
            shouldRedirectToDetail: false,
            isDeleteBrandModalOpen: false,
            isLoadErrorModalOpen: false,
            loading: false,
            brand: new Brand(),
        };
    }

    componentDidMount() {
        return this.fetchBrand();
    }

    fetchBrand = async () => {
        this.setState({
            loading: true,
        });

        try {
            const {brand} = await API.brands.detail(this.props.match.params.id);

            this.setState({
                brand,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});
        try {
            await API.brands.update(input, this.props.match.params.id);

            this.setState({
                shouldRedirectToDetail: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'BRANDS.BRAND_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({loading: false});
        }
    };

    handleDeleteBrand = async (e) => {
        try {
            await API.brands.delete(this.props.match.params.id);

            this.setState({
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'BRANDS.BRAND_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                isDeleteBrandModalOpen: false,
                loading: false,
            });
        }
    };

    toggleDeleteBrandModal = (show) => {
        this.setState({
            isDeleteBrandModalOpen: show,
        });
    };

    render() {
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                to={{
                    pathname: `/brands/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/brands',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : (
            <>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to={`/brands/${this.props.match.params.id}`}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'GENERAL.CANCEL',
                        })}
                    </Button>
                </div>
                <Form
                    data-test='editBrandComponent'
                    brand={this.state.brand}
                    handleDeleteBrand={this.handleDeleteBrand}
                    toggleDeleteBrandModal={this.toggleDeleteBrandModal}
                    isDeleteBrandModalOpen={this.state.isDeleteBrandModalOpen}
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                />
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'BRANDS.BRAND_ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'BRANDS.BRAND_ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'BRANDS.BRAND_ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchBrand}
                        >
                            {this.props.intl.formatMessage({
                                id: 'BRANDS.BRAND_ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(EditBrand);
