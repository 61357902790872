export default class BankAccount {
    constructor({
        userId = 0,
        bank = '',
        accountNumber = '',
        standarizedNumber = '',
        holderName = '',
    } = {}) {
        this.userId = userId;
        this.bank = bank;
        this.accountNumber = accountNumber;
        this.standarizedNumber = standarizedNumber;
        this.holderName = holderName;
    }
}
