import {Config} from '../config';

export default class User {
    id = 0;
    firstName = '';
    lastName = '';
    email = '';
    phone = '';
    pictureUrl = '';
    roles = [];
    isActive = true;
    permissions = [];
    isSuperAdmin = false;

    constructor(
        id = 0,
        firstName = '',
        lastName = '',
        email = '',
        phoneCountry = Config.defaultCountry,
        phone = '',
        pictureUrl = '',
        roles = [],
        isActive = true,
        permissions = [],
        isSuperAdmin = false,
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phoneCountry = phoneCountry;
        this.phone = phone;
        this.pictureUrl = pictureUrl;
        this.roles = roles;
        this.isActive = isActive;
        this.permissions = permissions;
        this.isSuperAdmin = isSuperAdmin;
    }
}
