import React from 'react';
import {injectIntl} from 'react-intl';
import {Redirect} from 'react-router-dom';

import Form from '../../components/form/brand/Form';
import API from '../../common/utils/API';

export class CreateBrand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldRedirectToIndex: false,
            loading: false,
        };
    }

    onSubmit = async (input) => {
        this.setState({loading: true});
        try {
            await API.brands.save(input);

            this.setState({
                shouldRedirectToIndex: true,
            });
        } catch (e) {
            this.setState({loading: false});
        }
    };

    render() {
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/brands',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'BRANDS.BRAND_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <Form
                data-test='createBrandComponent'
                loading={this.state.loading}
                handleSubmit={this.onSubmit}
            />
        );
    }
}

export default injectIntl(CreateBrand);
