import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';
import ComplainList from './ComplainList';
import ComplainDetail from './ComplainDetail';

import withLayout from '../../components/ui/Layout';

function Complain() {
    const intl = useIntl();

    return (
        <Switch>
            <PrivateRoute
                permission='REPORTS_INDEX'
                exact
                path='/complains'
                component={withLayout(
                    ComplainList,
                    intl.formatMessage({id: 'ROUTES.COMPLAINS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='REPORTS_SHOW'
                exact
                path='/complains/:id'
                component={withLayout(
                    ComplainDetail,
                    intl.formatMessage({id: 'ROUTES.COMPLAINS.SHOW'}),
                )}
            />
        </Switch>
    );
}

export default Complain;
