import React from 'react';
import BaseForm from 'react-bootstrap/Form';
import {Formik} from 'formik';
import * as Yup from 'yup';

import Input from '../Input';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/Button';
import LoadingButton from '../LoadingButton';

export default function CloseReportForm(props) {
    const intl = useIntl();
    const validationSchema = Yup.object({
        comments: Yup.string(),
        isOrdedCancelled: Yup.boolean(),
        isPostAvailable: Yup.boolean(),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input, 'closeReport');
    };

    const getInitialValues = () => {
        return {
            comments: props.comments || '',
            isOrdedCancelled: false,
            isPostAvailable: false,
        };
    };

    return (
        <Formik
            data-test='closeReportFormComponent'
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, setFieldValue, values, errors}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <BaseForm.Row>
                            <Input
                                sm='12'
                                type='textarea'
                                label={intl.formatMessage({
                                    id:
                                        'ORDERS_REPORTS.CLOSE_REPORT_COMMENTS_LABEL',
                                })}
                                placeholder={intl.formatMessage({
                                    id:
                                        'ORDERS_REPORTS.CLOSE_REPORT_COMMENTS_PLACEHOLDER',
                                })}
                                name='comments'
                                value={values.comments}
                                onChange={handleChange}
                                error={errors.comments}
                                isInvalid={errors.comments}
                            />
                            <Input
                                sm='12'
                                label={intl.formatMessage({
                                    id: 'ORDERS_REPORTS.CANCEL_ORDER',
                                })}
                                name='isOrdedCancelled'
                                id='check-cancel-order-from-report'
                                onChange={handleChange}
                                type='checkbox'
                            />
                            <Input
                                sm='12'
                                label={intl.formatMessage({
                                    id: 'ORDERS_REPORTS.REACTIVATE_POST',
                                })}
                                name='isPostAvailable'
                                id='check-reactivate-post'
                                onChange={handleChange}
                                type='checkbox'
                            />
                        </BaseForm.Row>
                        <BaseForm.Row className='justify-content-sm-end'>
                            <Button
                                type='button'
                                className='btn-bold mx-2 btn btn-dark btn-light-dark'
                                onClick={props.toggleCloseReportModal}
                            >
                                {intl.formatMessage({
                                    id: 'ORDERS_REPORTS.RETURN_CANCEL',
                                })}
                            </Button>
                            <LoadingButton
                                type='submit'
                                variant='success'
                                className='btn-bold'
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'ORDERS_REPORTS.ACCEPT_BUTTON',
                                })}
                            </LoadingButton>
                        </BaseForm.Row>
                    </BaseForm>
                </>
            )}
        </Formik>
    );
}
