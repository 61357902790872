import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Faq from '../../models/Faq';

const faqs = {
    get: async (page = 1, search = '') => {
        const {data: response} = await API.get(
            `/faqs/?page=${page}&search=${search}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((i) => new Faq(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    all: async () => {
        const {data: response} = await API.get('faqs/all');

        const faqs = response.data.map((i) => new Faq(i));

        return {
            faqs,
        };
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/faqs/${id}`);

        return {
            faq: new Faq(response.data),
        };
    },
    save: async (input) => {
        const {data: response} = await API.post('/faqs', input);

        return {
            faq: new Faq(response.data),
        };
    },
    delete: async (id) => {
        await API.delete(`/faqs/${id}`);
        return {};
    },
    update: async (input, id) => {
        const {data: response} = await API.put(`/faqs/${id}`, input);
        return {
            faq: new Faq(response.data),
        };
    },
    updateOrder: async (input) => {
        await API.put('/faqs', input);

        return {};
    },
};

export default faqs;
