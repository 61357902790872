import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.scss';

function Datepicker(props) {
    let date = props.value ? new Date(props.value) : null;

    if (date) {
        date = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
    }

    return (
        <DatePicker
            selected={date}
            onChange={props.onChange}
            placeholderText={props.placeholder}
            dateFormat='M/d/yyyy'
            className='form-control'
        />
    );
}

export default Datepicker;
