import React from 'react';

import {ImpulseSpinner} from 'react-spinners-kit';
import {Button} from 'react-bootstrap';

import './LoadingButton.scss';

function LoadingButton(props) {
    const {children, loading, className, ...buttonProps} = props;
    return (
        <>
            <Button
                {...buttonProps}
                className={`btn-loading ${className} ${
                    loading ? 'loading' : null
                }`}
            >
                <div className='loading-indicator'>
                    <ImpulseSpinner
                        size={30}
                        frontColor='white'
                        backColor='rgba(255,255,255,0.4)'
                        loading={true}
                    />
                </div>
                <span>{children}</span>
            </Button>
        </>
    );
}
export default LoadingButton;
