import React from 'react';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import {PostStatus} from '../../common/config/constants';
import StarterList from '../../components/starterList/StarterList';

class PostList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            // Meta for pagination
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            // Search parameters
            searchTimeout: 0,
            search: '',
            status: '',
        };
    }

    componentDidMount() {
        this.fetchPage(1);
    }

    fetchPage = (page) => {
        API.posts
            .get(page, this.state.search, this.state.status)
            .then((result) => {
                this.setState({
                    items: result.items,
                    page: result.page,
                    pageSize: result.pageSize,
                    pageCount: result.pageCount,
                    total: result.total,
                });
            });
    };

    onPageChange = (page) => {
        this.fetchPage(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                this.fetchPage(1);
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                this.fetchPage(1);
            },
        );
    };

    render() {
        return (
            <>
                <StarterList
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    searchKeyword={this.state.search}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'POSTS.USER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'POSTS.NAME',
                            }),
                            this.props.intl.formatMessage({
                                id: 'POSTS.CREATED_AT',
                            }),
                            this.props.intl.formatMessage({
                                id: 'POSTS.STATUS',
                            }),
                            this.props.intl.formatMessage({
                                id: 'POSTS.QUANTITY_AVAILABLE',
                            }),
                        ],
                        rows: this.state.items.map((item) => ({
                            content: [
                                item.id,
                                item.userName,
                                item.name,
                                item.formatDate,
                                this.props.intl.formatMessage({
                                    id: `POSTS.STATUS_${item.status.toUpperCase()}`,
                                }),
                                item.quantityAvailable > 0
                                    ? this.props.intl.formatMessage({
                                          id: 'GENERAL.YES',
                                      })
                                    : this.props.intl.formatMessage({
                                          id: 'GENERAL.NO',
                                      }),
                            ],
                            link: `/posts/${item.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            name: 'status',
                            value: this.state.status,
                            placeholder: this.props.intl.formatMessage({
                                id: 'POSTS.STATUS_PLACEHOLDER',
                            }),
                            options: Object.values(PostStatus).map((value) => ({
                                value,
                                label: this.props.intl.formatMessage({
                                    id: `POSTS.STATUS_${value.toUpperCase()}`,
                                }),
                            })),
                        },
                    ]}
                />
            </>
        );
    }
}

export default injectIntl(PostList);
