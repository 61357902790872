import React from 'react';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import StarterList from '../../components/starterList/StarterList';

export class FaqList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faqs: [],
            page: 1,
            pageCount: 12,
            total: 0,
            searchTimeout: 0,
            search: '',
        };
    }

    componentDidMount() {
        if (this.props.location?.state?.successMessage) {
            setTimeout(
                () => this.props.history.replace({successMessage: ''}),
                5000,
            );
        }

        return this.fetchFaqs();
    }

    fetchFaqs = async (page = 1) => {
        try {
            const result = await API.faqs.get(page, this.search);

            this.setState({
                faqs: result.items,
                page: result.page,
                pageSize: result.pageSize,
                pageCount: result.pageCount,
                total: result.total,
            });
        } catch (e) {
            this.setState({
                faqs: [],
            });
        }
    };

    onPageChange = (page) => {
        return this.fetchFaqs(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchFaqs();
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                return this.fetchFaqs();
            },
        );
    };

    render() {
        return (
            <>
                <StarterList
                    data-test='faqListComponent'
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'FAQS.QUESTION_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'FAQS.ANSWER_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'FAQS.POSITION_HEADER',
                            }),
                        ],
                        rows: this.state.faqs.map((faq, i) => ({
                            content: [
                                faq.id,
                                faq.question,
                                faq.answer,
                                this.state.page > 1
                                    ? this.state.pageSize *
                                          (this.state.page - 1) +
                                      (i + 1)
                                    : i + 1,
                            ],
                            link: `/faqs/${faq.id}/edit`,
                        })),
                    }}
                    addNewPermission={'FAQS_CREATE'}
                    addNewLink={`faqs/create`}
                    orderPermission={'FAQS_ORDER'}
                    orderLink={'faqs/order'}
                />
            </>
        );
    }
}

export default injectIntl(FaqList);
