import React from 'react';
import logo from '../../components/ui/assets/logo-color.svg';
import squareLogo from '../../components/ui/assets/logo-100x100.svg';
import './ColumnContainer.scss';

function ColumnContainer(props) {
    return (
        <div className='column-container'>
            <div className='aside'>
                <img
                    src={process.env.REACT_APP_LOGO_FULL ?? logo}
                    height='70'
                    className='fpi-logo d-none d-sm-none d-md-none d-lg-block d-xl-block'
                    alt='starter-logo'
                />
                <img
                    src={process.env.REACT_APP_LOGO_SQUARE ?? squareLogo}
                    height='100'
                    className='d-lg-none d-xl-none'
                    alt='starter-icon'
                />
            </div>
            <div className='control-column'>
                <div className='alt-logo'>
                    <img
                        src={process.env.REACT_APP_LOGO_SQUARE ?? squareLogo}
                        height='80'
                        className='mb-4'
                        alt='starter-icon'
                    />
                </div>
                {props.children}
            </div>
        </div>
    );
}

export default ColumnContainer;
