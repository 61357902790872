import React from 'react';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Row, Col} from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import API from '../../common/utils/API';
import User from '../../common/models/User';
import LoadingButton from '../../components/form/LoadingButton';
import ConfirmModal from '../../components/form/ConfirmModal';
import Can from '../../common/security/Can';
import {injectIntl} from 'react-intl';
import codes from 'country-calling-code';

class UserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: new User(),
            isLoadErrorModalOpen: false,
            error: '',
        };
    }

    componentDidMount = () => {
        this.loadUser();
    };

    loadUser = () => {
        this.setState({
            loading: true,
        });
        API.users
            .detail(this.props.match.params.id)
            .then((response) => {
                this.setState({
                    user: response.user,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadErrorModalOpen: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    toggleUserStatus = () => {
        const user = this.state.user;
        if (user.isActive) {
            API.users
                .deactivate(this.props.match.params.id)
                .then((response) => {
                    user.isActive = false;
                    this.setState({
                        user: user,
                        isToggleUserStatusModalOpen: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        error: this.props.intl.formatMessage({
                            id: 'USERS.GENERIC_BLOCK_USER_ERROR_MESSAGE',
                        }),
                        isToggleUserStatusModalOpen: false,
                    });
                });
        } else {
            API.users
                .activate(this.props.match.params.id)
                .then((response) => {
                    user.isActive = true;
                    this.setState({
                        user: user,
                        isToggleUserStatusModalOpen: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        error: this.props.intl.formatMessage({
                            id: 'USERS.GENERIC_UNBLOCK_USER_ERROR_MESSAGE',
                        }),
                        isToggleUserStatusModalOpen: false,
                    });
                });
        }
    };

    getCountryCode = (iso2) => {
        if (!iso2) {
            return '';
        }
        const country = codes.find((item) => item.isoCode2 === iso2) || {
            countryCodes: [],
        };
        return '+' + (country.countryCodes[0] || iso2);
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/users'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'USERS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <img
                                    hidden={!this.state.user.pictureUrl}
                                    className='img-fluid'
                                    src={this.state.user.pictureUrl}
                                    alt={`${this.state.user.firstName} ${this.state.user.lastName}`}
                                />
                            </Col>
                            <Col md={9}>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'USERS.USER_FIRST_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.user.firstName}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'USERS.USER_LAST_NAME_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.user.lastName}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'USERS.EMAIL_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>{this.state.user.email}</p>
                                    </Col>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'USERS.MOBILE_PHONE_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            {this.getCountryCode(
                                                this.state.user.phoneCountry,
                                            )}
                                            {this.state.user.phone}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <label className='field-title'>
                                            {this.props.intl.formatMessage({
                                                id: 'USERS.STATUS_FIELD_LABEL',
                                            })}
                                        </label>
                                        <p>
                                            <Badge
                                                variant={
                                                    this.state.user.isActive
                                                        ? 'success'
                                                        : 'danger'
                                                }
                                            >
                                                {this.state.user.isActive
                                                    ? this.props.intl.formatMessage(
                                                          {
                                                              id:
                                                                  'USERS.STATUS_ACTIVE',
                                                          },
                                                      )
                                                    : this.props.intl.formatMessage(
                                                          {
                                                              id:
                                                                  'USERS.STATUS_INACTIVE',
                                                          },
                                                      )}
                                            </Badge>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ROLE_AND_PRIVILEGES_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                {this.state.user.roles
                                    ? this.state.user.roles.map((role) => (
                                          <label
                                              className='badge badge-info mx-2'
                                              key={role.toString()}
                                          >
                                              {role.name}
                                          </label>
                                      ))
                                    : null}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <div className='d-flex justify-content-between'>
                    <div>
                        <Can run='USERS_ACTIVATE'>
                            <ConfirmModal
                                titleModal={this.props.intl.formatMessage({
                                    id: this.state.user.isActive
                                        ? 'USERS.BLOCK_USER_MODAL_TITLE'
                                        : 'USERS.UNBLOCK_USER_MODAL_TITLE',
                                })}
                                handleConfirm={this.toggleUserStatus}
                                handleShow={() =>
                                    this.setState({
                                        isToggleUserStatusModalOpen: true,
                                    })
                                }
                                handleClose={() =>
                                    this.setState({
                                        isToggleUserStatusModalOpen: false,
                                    })
                                }
                                show={this.state.isToggleUserStatusModalOpen}
                                variant={
                                    this.state.user.isActive
                                        ? `danger`
                                        : `success`
                                }
                                buttonClassName={`btn-bold ${
                                    this.state.user.isActive
                                        ? `btn-light-danger`
                                        : `btn-light-success`
                                }`}
                                buttonLabel={this.props.intl.formatMessage({
                                    id: this.state.user.isActive
                                        ? 'USERS.BLOCK_USER_BUTTON_LABEL'
                                        : 'USERS.UNBLOCK_USER_BUTTON_LABEL',
                                })}
                                buttonAcceptLabel={this.props.intl.formatMessage(
                                    {
                                        id: this.state.user.isActive
                                            ? 'USERS.CONFIRM_BLOCK_USER_BUTTON_LABEL'
                                            : 'USERS.CONFIRM_UNBLOCK_USER_BUTTON_LABEL',
                                    },
                                )}
                                buttonCloseLabel={this.props.intl.formatMessage(
                                    {
                                        id: this.state.user.isActive
                                            ? 'USERS.CANCEL_BLOCK_USER_BUTTON_LABEL'
                                            : 'USERS.CANCEL_UNBLOCK_USER_BUTTON_LABEL',
                                    },
                                )}
                            >
                                <p>
                                    {this.props.intl.formatMessage({
                                        id: this.state.user.isActive
                                            ? 'USERS.BLOCK_USER_CONFIRMATION_MESSAGE'
                                            : 'USERS.UNBLOCK_USER_CONFIRMATION_MESSAGE',
                                    })}
                                </p>
                            </ConfirmModal>
                        </Can>
                    </div>
                    <div>
                        <Can run='USERS_EDIT'>
                            <Button
                                as={NavLink}
                                to={`/users/${this.props.match.params.id}/edit`}
                                className='btn-warning btn-bold ml-2'
                            >
                                {this.props.intl.formatMessage({
                                    id: 'USERS.EDIT_USER_BUTTON_TEXT',
                                })}
                            </Button>
                        </Can>
                    </div>
                </div>
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'USERS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadUser}
                        >
                            {this.props.intl.formatMessage({
                                id: 'USERS.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(UserDetail);
