import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';

import PrivateRoute from '../../components/PrivateRoute';
import withLayout from '../../components/ui/Layout';
import FaqList from './FaqList';
import CreateFaq from './CreateFaq';
import EditFaq from './EditFaq';
import FaqOrderList from './FaqOrderList';

function Faqs() {
    const intl = useIntl();

    return (
        <Switch data-test='faqsComponent'>
            <PrivateRoute
                permission='FAQS_INDEX'
                exact
                path='/faqs'
                component={withLayout(
                    FaqList,
                    intl.formatMessage({id: 'ROUTES.FAQS.INDEX'}),
                )}
            />

            <PrivateRoute
                permission='FAQS_CREATE'
                exact
                path='/faqs/create'
                component={withLayout(
                    CreateFaq,
                    intl.formatMessage({id: 'ROUTES.FAQS.CREATE'}),
                )}
            />

            <PrivateRoute
                permission='FAQS_EDIT'
                exact
                path='/faqs/:id/edit'
                component={withLayout(
                    EditFaq,
                    intl.formatMessage({id: 'ROUTES.FAQS.EDIT'}),
                )}
            />

            <PrivateRoute
                permission='FAQS_ORDER'
                exact
                path='/faqs/order'
                component={withLayout(
                    FaqOrderList,
                    intl.formatMessage({id: 'ROUTES.FAQS.ORDER'}),
                )}
            />
        </Switch>
    );
}

export default Faqs;
