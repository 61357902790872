import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink, Redirect} from 'react-router-dom';

import Form from '../../components/form/faq/Form';
import API from '../../common/utils/API';
import Faq from '../../common/models/Faq';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LoadingButton from '../../components/form/LoadingButton';

export class EditFaq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldRedirectToIndex: false,
            isDeleteFaqModalOpen: false,
            isLoadErrorModalOpen: false,
            loading: false,
            faq: new Faq(),
        };
    }

    componentDidMount() {
        return this.fetchFaq();
    }

    fetchFaq = async () => {
        this.setState({
            loading: true,
        });

        try {
            const {faq} = await API.faqs.detail(this.props.match.params.id);

            this.setState({
                faq,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({loading: true});

        try {
            await API.faqs.update(input, this.props.match.params.id);

            this.setState({
                shouldRedirectToIndex: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'FAQS.SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({loading: false});
        }
    };

    handleDeleteFaq = async (e) => {
        try {
            await API.faqs.delete(this.props.match.params.id);

            this.setState({
                shouldRedirectToIndex: true,
                loading: false,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'FAQS.SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                isDeleteFaqModalOpen: false,
                loading: false,
            });
        }
    };

    toggleDeleteFaqModal = (show) => {
        this.setState({
            isDeleteFaqModalOpen: show,
        });
    };

    render() {
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/faqs',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : (
            <>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to={`/faqs/`}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'GENERAL.CANCEL',
                        })}
                    </Button>
                </div>
                <Form
                    data-test='editFaqComponent'
                    loading={this.state.loading}
                    handleSubmit={this.onSubmit}
                    handleDeleteFaq={this.handleDeleteFaq}
                    toggleDeleteFaqModal={this.toggleDeleteFaqModal}
                    isDeleteFaqModalOpen={this.state.isDeleteFaqModalOpen}
                    faq={this.state.faq}
                />
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'FAQS.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'FAQS.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'FAQS.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchFaq}
                        >
                            {this.props.intl.formatMessage({
                                id: 'FAQS.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(EditFaq);
