import React from 'react';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import Brand from '../../common/models/Brand';
import Alert from 'react-bootstrap/Alert';
import Can from '../../common/security/Can';
import Button from 'react-bootstrap/Button';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import {Col, Row} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import LoadingButton from '../../components/form/LoadingButton';

export class BrandDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brand: new Brand(),
            error: '',
            isLoadErrorModalOpen: false,
            loading: false,
        };
    }

    componentDidMount() {
        return this.fetchBrand();
    }

    fetchBrand = async () => {
        this.setState({loading: true});
        try {
            const {brand} = await API.brands.detail(this.props.match.params.id);

            this.setState({
                brand: {...brand},
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch ({response}) {
            this.setState({
                error: Object.values(response?.data?.messages || {}).shift(),
                loading: false,
                isLoadErrorModalOpen: true,
            });
        }
    };

    render() {
        return (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={!!this.state.error}
                    dismissible={false}
                >
                    <p className='mb-0'>{this.state.error}</p>
                </Alert>
                <div className='mb-4 d-flex justify-content-end'>
                    <Can run='BRANDS_EDIT'>
                        <Button
                            as={NavLink}
                            to={`/brands/${this.props.match.params.id}/edit`}
                            className='btn-info btn-bold mx-2'
                        >
                            {this.props.intl.formatMessage({
                                id: 'BRANDS.EDIT_BUTTON_TEXT',
                            })}
                        </Button>
                    </Can>
                    <Button
                        as={NavLink}
                        to='/brands'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card data-test='detailBrandComponent' className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'BRANDS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BRANDS.ID_LABEL',
                                    })}
                                </label>
                                <p>{this.state.brand.id}</p>
                            </Col>
                            <Col sm={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'BRANDS.NAME_LABEL',
                                    })}
                                </label>
                                <p>{this.state.brand.name}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'BRANDS.BRAND_ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'BRANDS.BRAND_ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'BRANDS.BRAND_ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchBrand}
                        >
                            {this.props.intl.formatMessage({
                                id: 'BRANDS.BRAND_ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(BrandDetail);
