import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Brands from './brands/Brands';
import Categories from './categories/Categories';
import Returns from './returns/Returns';
import Complain from './complains/Complains';
import Users from './users/Users';
import Roles from './roles/Roles';
import Posts from './posts/Posts';
import Orders from './orders/Orders';
import Dashboard from './dashboard/Dashboard';
import Faqs from './faqs/Faqs';
import Fees from './fees/Fees';
import withLayout from '../components/ui/Layout';
import {useIntl} from 'react-intl';

import OrdersReports from './orders_reports/OrdersReports';

function MainContainer(props) {
    const intl = useIntl();

    return (
        <Switch>
            <Route
                exact
                path='/'
                component={withLayout(
                    Dashboard,
                    intl.formatMessage({id: 'MENU.DASHBOARD'}),
                )}
            />
            <Route path='/brands' component={Brands} />
            <Route path='/categories' component={Categories} />
            <Route path='/returns' component={Returns} />
            <Route path='/users' component={Users} />
            <Route path='/roles' component={Roles} />
            <Route path='/posts' component={Posts} />
            <Route path='/complains' component={Complain} />
            <Route path='/orders' component={Orders} />
            <Route path='/orders-reports' component={OrdersReports} />
            <Route path='/faqs' component={Faqs} />
            <Route path='/fees' component={Fees} />
        </Switch>
    );
}

const logout = function () {
    return {
        type: 'LOGOUT',
    };
};

const mapStateToProps = function (state) {
    return {
        user: state.sessionReducer.user,
        title: state.testReducer.title,
        content: state.testReducer.content,
    };
};

const mapDispatchToProps = {
    logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
