import React from 'react';

import API from '../../common/utils/API';
import StarterList from '../../components/starterList/StarterList';
import {injectIntl} from 'react-intl';
import {OrderCaseStatus, OrderCaseTopic} from '../../common/config/constants';

export class OrderReportList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ordersReports: [],
            page: 1,
            pageSize: 12,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            userId: '',
            sellerId: '',
            startsAt: '',
            endsAt: '',
            topic: '',
            status: '',
        };
    }

    componentDidMount() {
        return this.fetchOrdersReports();
    }

    fetchOrdersReports = async (page = 1) => {
        const result = await API.ordersReports.get(
            page,
            this.state.status,
            this.state.topic,
            this.state.userId,
            this.state.sellerId,
            this.state.startsAt,
            this.state.endsAt,
        );

        this.setState({
            ordersReports: result.items,
            page: result.page,
            pageSize: result.pageSize,
            pageCount: result.pageCount,
            total: result.total,
        });
    };

    onPageChange = (page) => {
        return this.fetchOrdersReports(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchOrdersReports(1);
            }, 300),
        });
    };

    onAutocompleteOrDateSearchChange = (value, name) => {
        this.setState({
            [name]: value?.id || value || '',
            searchTimeout: setTimeout(() => {
                return this.fetchOrdersReports(1);
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                return this.fetchOrdersReports(1);
            },
        );
    };

    handleAutocompleteSearch = async (query) => {
        const {items: users} = await API.users.get(1, query);
        const options = users.map((user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
        }));

        return options;
    };

    render() {
        return (
            <>
                <StarterList
                    data-test='orderReportListComponent'
                    hideSearchInput={true}
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    onAutocompleteOrDateSearchChange={
                        this.onAutocompleteOrDateSearchChange
                    }
                    handleAutocompleteSearch={this.handleAutocompleteSearch}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.BUYER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.SELLER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.TOPIC_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.DATE_OF_REPORT',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.DATE_OF_PURCHASE',
                            }),
                            this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.STATUS',
                            }),
                        ],
                        rows: this.state.ordersReports.map((orderReport) => ({
                            content: [
                                orderReport.id,
                                orderReport.buyerFullName,
                                orderReport.sellerFullName,
                                this.props.intl.formatMessage({
                                    id: `ORDERS_REPORTS.TOPIC_${orderReport.topic.toUpperCase()}`,
                                }),
                                new Date(
                                    orderReport.createdAt,
                                ).toLocaleDateString(),
                                new Date(
                                    orderReport.order.createdAt,
                                ).toLocaleDateString(),
                                this.props.intl.formatMessage({
                                    id: `ORDERS_REPORTS.STATUS_${orderReport.status.toUpperCase()}`,
                                }),
                            ],
                            background: orderReport.isRead ? '' : '#89adee94',
                            link: `/orders-reports/${orderReport.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            name: 'userId',
                            value: this.state.userId,
                            autocomplete: true,
                            labelKey: 'name',
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.TYPE_HERE_TO_SEARCH_BY_USER',
                            }),
                        },
                        {
                            name: 'sellerId',
                            value: this.state.sellerId,
                            autocomplete: true,
                            labelKey: 'name',
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.TYPE_HERE_TO_SEARCH_BY_SELLER',
                            }),
                        },
                        {
                            name: 'startsAt',
                            value: this.state.startsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_FROM',
                            }),
                        },
                        {
                            name: 'endsAt',
                            value: this.state.endsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_TO',
                            }),
                        },
                        {
                            name: 'status',
                            value: this.state.status,
                            placeholder: this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.STATUS_PLACEHOLDER',
                            }),
                            options: Object.values(OrderCaseStatus).map(
                                (value) => ({
                                    value,
                                    label: this.props.intl.formatMessage({
                                        id: `ORDERS_REPORTS.STATUS_${value.toUpperCase()}`,
                                    }),
                                }),
                            ),
                        },
                        {
                            name: 'topic',
                            value: this.state.topic,
                            placeholder: this.props.intl.formatMessage({
                                id: 'ORDERS_REPORTS.TOPIC_PLACEHOLDER',
                            }),
                            options: Object.values(OrderCaseTopic).map(
                                (value) => ({
                                    value,
                                    label: this.props.intl.formatMessage({
                                        id: `ORDERS_REPORTS.TOPIC_${value.toUpperCase()}`,
                                    }),
                                }),
                            ),
                        },
                    ]}
                />
            </>
        );
    }
}

export default injectIntl(OrderReportList);
