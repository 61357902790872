import React from 'react';
import {Switch} from 'react-router-dom';
import {useIntl} from 'react-intl';
import PrivateRoute from '../../components/PrivateRoute';

import withLayout from '../../components/ui/Layout';
import BrandList from './BrandList';
import BrandDetail from './BrandDetail';
import CreateBrand from './CreateBrand';
import EditBrand from './EditBrand';

function Brands() {
    const intl = useIntl();

    return (
        <Switch data-test='brandsComponent'>
            <PrivateRoute
                permission='BRANDS_INDEX'
                exact
                path='/brands'
                component={withLayout(
                    BrandList,
                    intl.formatMessage({id: 'ROUTES.BRANDS.INDEX'}),
                )}
            />
            <PrivateRoute
                permission='BRANDS_CREATE'
                exact
                path='/brands/create'
                component={withLayout(
                    CreateBrand,
                    intl.formatMessage({id: 'ROUTES.BRANDS.CREATE'}),
                )}
            />
            <PrivateRoute
                permission='BRANDS_SHOW'
                exact
                path='/brands/:id'
                component={withLayout(
                    BrandDetail,
                    intl.formatMessage({id: 'ROUTES.BRANDS.SHOW'}),
                )}
            />
            <PrivateRoute
                permission='BRANDS_EDIT'
                exact
                path='/brands/:id/edit'
                component={withLayout(
                    EditBrand,
                    intl.formatMessage({id: 'ROUTES.BRANDS.EDIT'}),
                )}
            />
        </Switch>
    );
}

export default Brands;
