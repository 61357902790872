import general from './general/en';
import starterList from './starter_list/en';
import brands from './brands/en';
import categories from './categories/en';
import complains from './complains/en';
import roles from './roles/en';
import orders from './orders/en';
import ordersReports from './orders_reports/en';
import users from './users/en';
import posts from './posts/en';
import routes from './routes/en';
import validation from './validation/en';
import attributes from './attributes/en';
import returns from './returns/en';
import faqs from './faqs/en';

export default {
    ...general,
    ...starterList,
    ...brands,
    ...categories,
    ...complains,
    ...roles,
    ...orders,
    ...ordersReports,
    ...users,
    ...posts,
    ...routes,
    ...validation,
    ...attributes,
    ...faqs,
    ...returns,
};
