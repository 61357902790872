import React from 'react';
import BaseForm from 'react-bootstrap/Form';
import {Formik} from 'formik';
import * as Yup from 'yup';

import Input from '../Input';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/Button';
import LoadingButton from '../LoadingButton';

export default function ReturnMoneyForm(props) {
    const intl = useIntl();
    const validationSchema = Yup.object({
        amount: Yup.number()
            .required(
                intl.formatMessage({
                    id: 'ORDERS_REPORTS.VALIDATION.AMOUNT_MISSING',
                }),
            )
            .typeError(
                intl.formatMessage({
                    id: 'ORDERS_REPORTS.VALIDATION.NUMBER_FORMAT',
                }),
            ),
    });

    const onSubmit = (input) => {
        props.handleSubmit(input, 'returnMoney');
    };

    const getInitialValues = () => {
        return {
            amount: props.amount || '',
            chargeSeller: props.chargeSeller || false,
            wallet: true,
        };
    };

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
        >
            {({handleSubmit, handleChange, values, errors}) => (
                <>
                    <BaseForm onSubmit={handleSubmit}>
                        <BaseForm.Row>
                            <Input
                                sm='12'
                                type='text'
                                label={intl.formatMessage({
                                    id: 'ORDERS_REPORTS.RETURN_AMOUNT_LABEL',
                                })}
                                placeholder={intl.formatMessage({
                                    id:
                                        'ORDERS_REPORTS.RETURN_AMOUNT_FIELD_PLACEHOLDER',
                                })}
                                name='amount'
                                value={values.amount}
                                onChange={handleChange}
                                error={errors.amount}
                                isInvalid={errors.amount}
                            />
                        </BaseForm.Row>
                        <BaseForm.Row className='justify-content-sm-end'>
                            <Button
                                type='button'
                                className='btn-bold mx-2 btn btn-dark btn-light-dark'
                                onClick={props.toggleReturnPaymentModal}
                            >
                                {intl.formatMessage({
                                    id: 'ORDERS_REPORTS.RETURN_CANCEL',
                                })}
                            </Button>
                            <LoadingButton
                                type='submit'
                                variant='success'
                                className='btn-bold'
                                loading={props.loading}
                            >
                                {intl.formatMessage({
                                    id: 'ORDERS_REPORTS.ACCEPT_BUTTON',
                                })}
                            </LoadingButton>
                        </BaseForm.Row>
                    </BaseForm>
                </>
            )}
        </Formik>
    );
}
