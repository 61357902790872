import React from 'react';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import {Form, Row, Col} from 'react-bootstrap';
import API from '../../common/utils/API';
import Role from '../../common/models/Role';
import LoadingButton from '../../components/form/LoadingButton';
import {injectIntl} from 'react-intl';
import Can from '../../common/security/Can';

class RoleDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            role: new Role(),
            permissions: undefined,
            isLoadErrorModalOpen: false,
            permissionGroups: [],
        };
    }

    componentDidMount = () => {
        this.loadRole();
        this.loadPermissions();
    };

    loadPermissions = () => {
        API.permissions.get().then((permissions) => {
            let groups = this.state.permissionGroups;
            groups = groups.concat(
                permissions.map((permission) => permission.group),
            );

            this.setState({
                permissionGroups: groups,
                permissions: permissions,
            });
        });
    };

    loadRole = () => {
        this.setState({
            loading: true,
        });
        API.roles
            .detail(this.props.match.params.id)
            .then((response) => {
                let groups = this.state.permissionGroups;
                groups = groups.concat(
                    response.role.permissions.map(
                        (permission) => permission.group,
                    ),
                );
                this.setState({
                    role: response.role,
                    isLoadErrorModalOpen: false,
                    permissionGroups: groups,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoadErrorModalOpen: true,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        return (
            <>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/roles'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={6}>
                                <label className='field-title'>
                                    {this.props.intl.formatMessage({
                                        id: 'ROLES.ROLE_NAME_FIELD_LABEL',
                                    })}
                                </label>
                                <p>{this.state.role.name}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.PRIVILEGE_PROFILE_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body className='p-0'>
                        <Table className='my-0'>
                            <tbody>
                            {this.state.permissionGroups
                                .filter((x, i, a) => a.indexOf(x) === i)
                                .sort()
                                .map((group, key) => (
                                    <tr key={key}>
                                        <td>{group}</td>
                                        <td>
                                            {(
                                                this.state.permissions ||
                                                this.state.role.permissions
                                            )
                                                .filter(
                                                    (permission) =>
                                                        permission.group ===
                                                        group,
                                                )
                                                .map((permission, key) => (
                                                    <Row key={key}>
                                                        <Col>
                                                            <Form.Row>
                                                                <Form.Check
                                                                    disabled
                                                                    type='switch'
                                                                    id={
                                                                        permission.code
                                                                    }
                                                                    label={
                                                                        permission.description
                                                                    }
                                                                    checked={this.state.role.permissions.find(
                                                                        (
                                                                            selectedPermission,
                                                                        ) =>
                                                                            permission.code ===
                                                                            selectedPermission.code,
                                                                    )}
                                                                    value={
                                                                        permission.code
                                                                    }
                                                                />
                                                            </Form.Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                <div className='d-flex justify-content-end'>
                    <Can run='ROLES_EDIT'>
                        <Button
                            as={NavLink}
                            to={`/roles/${this.props.match.params.id}/edit`}
                            className='btn-warning btn-bold'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.EDIT_ROLE_BUTTON_TEXT',
                            })}
                        </Button>
                    </Can>
                </div>
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.loadRole}
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(RoleDetail);
