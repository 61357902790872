import {API} from '../API';

const media = {
    save: async (input) => {
        const {data: response} = await API.post('/media', input, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
                type: 'formData',
            },
        });

        return {
            iconUrl: response.data,
        };
    },
};

export default media;
