export default class Queue {
    static queue = [];
    static pendingPromise = false;

    static enqueue(promise) {
        return new Promise((resolve, reject) => {
            this.queue.push({
                promise,
                resolve,
                reject,
            });

            return this.dequeue();
        });
    }

    static async dequeue() {
        if (this.pendingPromise) {
            return false;
        }

        const item = this.queue.shift();
        if (!item) {
            return false;
        }

        try {
            this.pendingPromise = true;

            const value = await item.promise();

            this.pendingPromise = false;
            item.resolve(value);
            return this.dequeue();
        } catch (e) {
            this.pendingPromise = false;
            item.reject(e);
            return this.dequeue();
        }
    }
}
