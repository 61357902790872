import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Badge from 'react-bootstrap/Badge';

import * as FeatherIcons from 'react-icons/fi';

import {useIntl} from 'react-intl';

import './Menu.scss';
import API from '../../common/utils/API';

function Menu() {
    const intl = useIntl();

    const [returnsNumber, setReturnsNumber] = useState(0);
    const [orderCasesNumber, setOrderCasesNumber] = useState(0);

    const setReturnsCount = () => {
        API.returns.get(1, '', '', '', '', '', '', '', false).then((result) => {
            setReturnsNumber(result.total);
        });
    };

    const setOrderCasesCount = () => {
        API.ordersReports
            .get(1, '', '', '', '', '', '', false)
            .then((result) => {
                setOrderCasesNumber(result.total);
            });
    };

    useEffect(() => {
        setReturnsCount();
        setOrderCasesCount();
        const returnsIntervalId = setInterval(setReturnsCount, 5000);
        const intOrderCases = setInterval(setReturnsCount, 5000);
        return () => {
            clearInterval(returnsIntervalId);
            clearInterval(intOrderCases);
        };
    }, []);

    return (
        <Nav className='menu'>
            <Nav.Link as={NavLink} to='/' exact={true}>
                <FeatherIcons.FiGrid />
                <span>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</span>
            </Nav.Link>
            <Nav.Item as='a' className='menu-separator'>
                <FeatherIcons.FiMoreHorizontal /> <span>Configuración</span>
            </Nav.Item>
            <Nav.Link as={NavLink} to='/users'>
                <FeatherIcons.FiUsers />
                <span>{intl.formatMessage({id: 'MENU.USERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/roles'>
                <FeatherIcons.FiUserCheck />
                <span>{intl.formatMessage({id: 'MENU.ROLES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/posts'>
                <FeatherIcons.FiImage />
                <span>{intl.formatMessage({id: 'MENU.POSTS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/complains'>
                <FeatherIcons.FiAlertOctagon />
                <span>{intl.formatMessage({id: 'MENU.COMPLAINS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/categories'>
                <FeatherIcons.FiBookmark />
                <span>{intl.formatMessage({id: 'MENU.CATEGORIES'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/brands'>
                <FeatherIcons.FiTag />
                <span>{intl.formatMessage({id: 'MENU.BRANDS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/orders'>
                <FeatherIcons.FiDollarSign />
                <span>{intl.formatMessage({id: 'MENU.ORDERS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/orders-reports'>
                <FeatherIcons.FiHelpCircle />
                <span>{intl.formatMessage({id: 'MENU.ORDERS_REPORTS'})}</span>
                {orderCasesNumber > 0 && (
                    <Badge pill variant='dark' className='float-right mt-0'>
                        <span style={{fontSize: '1.5em'}}>
                            {orderCasesNumber}
                        </span>
                    </Badge>
                )}
            </Nav.Link>
            <Nav.Link as={NavLink} to='/returns'>
                <FeatherIcons.FiRotateCcw />
                <span>{intl.formatMessage({id: 'MENU.RETURNS'})}</span>
                {returnsNumber > 0 && (
                    <Badge pill variant='dark' className='float-right mt-0'>
                        <span style={{fontSize: '1.5em'}}>{returnsNumber}</span>
                    </Badge>
                )}
            </Nav.Link>
            <Nav.Link as={NavLink} to='/faqs'>
                <FeatherIcons.FiHelpCircle />
                <span>{intl.formatMessage({id: 'MENU.FAQS'})}</span>
            </Nav.Link>
            <Nav.Link as={NavLink} to='/fees'>
                <FeatherIcons.FiBarChart2 />
                <span>{intl.formatMessage({id: 'MENU.FEES'})}</span>
            </Nav.Link>
        </Nav>
    );
}

export default Menu;
