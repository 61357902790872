import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Card from 'react-bootstrap/cjs/Card';
import ListGroup from 'react-bootstrap/cjs/ListGroup';

import './OrderList.scss';

function OrderList(props) {
    const [items, setItems] = useState([]);
    const intl = useIntl();

    const onDragEnd = (result) => {
        // Check if item was dropped outside
        if (!result.destination) return;

        // Check if item was dropped in the same place
        if (
            (result.source.droppableId =
                result.destination.droppableId &&
                result.source.index === result.destination.index)
        )
            return;

        let orderedItems = [...items];
        const item = items[result.source.index];

        orderedItems.splice(result.source.index, 1);
        orderedItems.splice(result.destination.index, 0, item);

        setItems(orderedItems);
        props.updateFaqsOrder(orderedItems);
    };

    useEffect(() => {
        setItems([...props.items]);
    }, [props.items]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className='order-list'>
                <Card>
                    <Card.Header>
                        {intl.formatMessage({
                            id: 'FAQS.VALIDATION.ANSWER_MISSING',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Droppable droppableId={props.id}>
                            {(provided) => (
                                <ListGroup
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {items.map((item, i) => (
                                        <Draggable
                                            draggableId={`item-${item.id}`}
                                            index={i}
                                            key={item.id}
                                        >
                                            {(provided) => (
                                                <ListGroup.Item
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <h5>{item.question}</h5>
                                                    <p>{item.answer}</p>
                                                </ListGroup.Item>
                                            )}
                                        </Draggable>
                                    ))}

                                    {provided.placeholder}
                                </ListGroup>
                            )}
                        </Droppable>
                    </Card.Body>
                </Card>
            </div>
        </DragDropContext>
    );
}

export default OrderList;
