import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Return from '../../models/Return';

const returns = {
    get: async (
        page = 1,
        userId = '',
        sellerId = '',
        startsAt = '',
        endsAt = '',
        refunded = '',
        returnStatus = '',
        reason = '',
        isRead = '',
    ) => {
        const {data: response} = await API.get(
            `returns/?page=${page}&userId=${userId}&sellerId=${sellerId}&startsAt=${startsAt}&endsAt=${endsAt}&returnStatus=${returnStatus}&reason=${reason}${
                refunded ? `&refunded=${refunded}` : ''
            }${isRead !== '' ? `&isRead=${isRead}` : ''}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((i) => new Return(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/returns/${id}`);

        return {
            returnOrder: new Return(response.data),
        };
    },
    confirm: async (input, id) => {
        const {data: response} = await API.post(`/returns/${id}/review`, input);

        return {
            returnOrder: new Return(response.data),
        };
    },
    refund: async (id) => {
        const {data: response} = await API.post(`/returns/${id}/refund`);

        return {
            returnOrder: new Return(response.data),
        };
    },
    read: async (id) => {
        await API.post(`/returns/${id}/read`);
        return true;
    },
};

export default returns;
