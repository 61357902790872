import React, {useEffect, useState} from 'react';
import {ChromePicker} from 'react-color';

import Input from './Input';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import './ColorPicker.scss';

const ColorPicker = (props) => {
    const [color, setColor] = useState(props.color);
    const [displayColorPicker, toggleColorPicker] = useState(false);
    const popover = {
        position: 'absolute',
        left: '7px',
        zIndex: '2',
    };
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };

    const handleClick = () => {
        toggleColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        toggleColorPicker(false);
    };

    const handlePickerChange = (color) => {
        setColor(color.hex);
        props.onChange(color.hex);
    };

    const handleInputChange = (e) => {
        setColor(e.target.value);
        props.onChange(e.target.value);
    };

    useEffect(() => {
        setColor(props.color);
    }, [props.color]);

    return (
        <Form.Group
            as={Col}
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
            className='color-picker-form-group'
        >
            <Input
                type='text'
                autoComplete='off'
                label={props.label}
                placeholder={props.placeholder}
                name='color'
                value={color}
                onChange={handleInputChange}
                onFocus={handleClick}
                error={props.error}
                isInvalid={props.error}
                className='color-picker-input'
            />
            {displayColorPicker ? (
                <div style={popover}>
                    <div style={cover} onClick={handleClose} />
                    <ChromePicker
                        disableAlpha={true}
                        color={color}
                        onChange={handlePickerChange}
                    />
                </div>
            ) : null}
        </Form.Group>
    );
};

export default ColorPicker;
