import React from 'react';
import {injectIntl} from 'react-intl';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

const fakedata = [
    {
        name: 'Enero',
        uv: 5,
        pv: 2400,
        amt: 2400,
        provendidos: 6,
    },
    {
        name: 'Febrero',
        uv: 2,
        pv: 1398,
        amt: 2210,
        provendidos: 20,
    },
    {
        name: 'Marzo',
        uv: 6,
        pv: 9800,
        amt: 2290,
        provendidos: 5,
    },
    {
        name: 'Abril',
        uv: 2,
        pv: 3908,
        amt: 2000,
        provendidos: 2,
    },
];

class ReportContainer extends React.Component {
    constructor(props) {
        super();
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            loading: false,
        };
    }

    handleSelect = (ranges) => {
        this.setState({
            startDate: ranges.selection.startDate,
            endDate: ranges.selection.endDate,
        });
    };

    render() {
        return (
            <React.Fragment>
                <div style={{width: '100%', height: '600px'}}>
                    <ResponsiveContainer width='100%' height='100%'>
                        <BarChart
                            data={this.props.data}
                            width={500}
                            height={300}
                            margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='name' />
                            <YAxis />
                            {this.props.YAxisNumber >= 2 && (
                                <YAxis yAxisId='axis2' orientation='right' />
                            )}
                            <Tooltip />
                            <Legend />
                            {this.props.series.map((serie) => (
                                <Bar
                                    key={serie.dataKey}
                                    dataKey={serie.dataKey}
                                    name={serie.name}
                                    yAxisId={serie.yAxisId || 0}
                                    fill={serie.color}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </React.Fragment>
        );
    }
}

export default injectIntl(ReportContainer);
