import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import {DateRange} from 'react-date-range';
import {es} from 'react-date-range/dist/locale';
import ReportContainer from '../../components/reports/ReportContainer';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const CustomMenu = React.forwardRef(
    (
        {
            children,
            style,
            className,
            'aria-labelledby': labeledBy,
            startDate,
            endDate,
            onDateRangeChange,
        },
        ref,
    ) => {
        const selectionRange = {
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
        };
        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <DateRange
                    locale={es}
                    editableDateInputs={true}
                    onChange={onDateRangeChange}
                    moveRangeOnFirstSelection={false}
                    ranges={[selectionRange]}
                />
            </div>
        );
    },
);

const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
    <a
        href=''
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </a>
));

class ReportCard extends React.Component {
    constructor(props) {
        super();
        this.state = {};
    }

    formatDate = (date) => {
        const dayNumber = ('0' + date.getDate()).slice(-2);
        const monthNumber = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        return `${dayNumber}/${monthNumber}/${year}`;
    };

    onDateRangeChange = (range) => {
        this.props.onDateRangeChange(
            range.selection.startDate,
            range.selection.endDate,
        );
    };

    render() {
        const toogleTitle = `${this.formatDate(
            this.props.startDate,
        )} - ${this.formatDate(this.props.endDate)}`;
        return (
            <Card>
                <Card.Header>
                    {this.props.title}
                    <Dropdown>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id='dropdown-custom-components'
                        >
                            {toogleTitle}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                            as={CustomMenu}
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            onDateRangeChange={this.onDateRangeChange}
                        ></Dropdown.Menu>
                    </Dropdown>
                </Card.Header>
                <Card.Body>
                    <ReportContainer
                        data={this.props.data}
                        series={this.props.series}
                        YAxisNumber={this.props.YAxisNumber}
                    />
                </Card.Body>
            </Card>
        );
    }
}

export default injectIntl(ReportCard);
