import axios from 'axios';
import roles from './roles/Roles';
import session from './session/Session';
import bankAccounts from './bankAccounts/BankAccounts';
import brands from './brands/Brands';
import categories from './categories/Categories';
import complains from './complains/Complains';
import orders from './orders/Orders';
import ordersReports from './orders_reports/OrdersReports';
import users from './users/Users';
import permissions from './permissions/Permissions';
import posts from './posts/Posts';
import media from './media/Media';
import faqs from './faqs/Faqs';
import fees from './fees/Fees';
import returns from './returns/Returns';
import reports from './reports/Reports';
import {store} from '../redux/StoreReducers';

const authTokens = {
    bearer: undefined,
    refreshToken: undefined,
};

export const API = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        Accept: 'application/json',
        Authorization: ' bearer',
    },
});

// Export setters for bearer and refresh token

export const setBearerToken = (token) => {
    authTokens.bearer = token;
    API.defaults.headers.common['Authorization'] = token;
};

export const setRefreshToken = (token) => {
    authTokens.refreshToken = token;
};

API.interceptors.request.use((config) => {
    if (store.getState().sessionReducer.session) {
        config.headers.Authorization =
            'Bearer ' + store.getState().sessionReducer.session.token;
        config.headers['Accept-Language'] = store.getState().langReducer.lang;
    }
    return config;
});

// Set interceptor for refreshing auth token
API.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        // const originalRequest = error.config;
        // if (error.response.status === 401 && !originalRequest._retry && authTokens.refreshToken) {

        //     // // TODO
        //     // // Cal refresh token web service, update store
        //     // originalRequest._retry = true;
        //     // return axios.post('/auth/token',
        //     //     {
        //     //         "refresh_token": localStorageService.getRefreshToken()
        //     //     })
        //     //     .then(res => {
        //     //         if (res.status === 201) {
        //     //             // 1) put token to LocalStorage
        //     //             localStorageService.setToken(res.data);

        //     //             // 2) Change Authorization header
        //     //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();

        //     //             // 3) return originalRequest object with Axios.
        //     //             return axios(originalRequest);
        //     //         }
        //     //     })
        // }

        // return Error object with Promise
        return Promise.reject(error);
    },
);

export default {
    users,
    roles,
    session,
    permissions,
    posts,
    orders,
    ordersReports,
    categories,
    bankAccounts,
    brands,
    complains,
    media,
    faqs,
    fees,
    returns,
    reports,
};
