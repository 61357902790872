import React from 'react';
import {Redirect, NavLink} from 'react-router-dom';

import API from '../../common/utils/API';

import Input from '../../components/form/Input';
import LoadingButton from '../../components/form/LoadingButton';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Role from '../../common/models/Role';
import Table from 'react-bootstrap/Table';
import {injectIntl} from 'react-intl';

class CreateRole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shouldRedirectToIndex: false,
            role: new Role(),
            error: '',
            errors: {},
            permissions: [],
            permissionGroups: [],
        };
    }

    componentDidMount() {
        API.permissions.get().then((response) => {
            this.setState({
                permissionGroups: response.map(
                    (permission) => permission.group,
                ),
                permissions: response,
            });
        });
    }

    validateForm = () => {
        let result = true;
        const errors = this.state.errors;

        if (this.state.role.name.trim().length === 0) {
            result = false;
            errors.name = this.props.intl.formatMessage({
                id: 'ROLES.VALIDATION.ROLE_NAME_MISSING',
            });
        } else {
            delete errors.name;
        }

        this.setState({
            errors,
        });

        return result;
    };

    handleNameChange = (e) => {
        if (this.state.errors.name) {
            this.validateForm();
        }
        const role = this.state.role;
        role.name = e.target.value;
        this.setState({
            role: role,
        });
    };

    handleCreateRoleFormSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({
                loading: true,
            });
            const role = {
                name: this.state.role.name,
                permissions: this.state.role.permissions.map(
                    (permission) => permission.code,
                ),
            };
            API.roles
                .save(role)
                .then((response) => {
                    this.setState({
                        shouldRedirectToIndex: true,
                    });
                })
                .catch((e) => {
                    if (e.response && e.response.status === 422) {
                        this.setState({
                            errors: e.response.data.messages,
                        });
                    }
                    this.setState({
                        error: {},
                        loading: false,
                    });
                });
        }
    };

    handlePermissionToggle = (e, permission) => {
        const role = this.state.role;
        let permissions = role.permissions;
        if (
            permissions.find(
                (selectedPermission) =>
                    permission.code === selectedPermission.code,
            )
        ) {
            permissions = permissions.filter(
                (selectedPermission) =>
                    permission.code !== selectedPermission.code,
            );
        } else {
            permissions.push(permission);
        }
        role.permissions = permissions;
        this.setState({
            role,
        });
    };

    render() {
        return this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/roles',
                    state: {
                        successMessage: this.props.intl.formatMessage({
                            id: 'ROLES.ROLE_SUCCESSFULLY_CREATED_MESSAGE',
                        }),
                    },
                }}
            />
        ) : (
            <>
                <Alert
                    variant='danger'
                    className='mb-4'
                    show={this.state.error}
                >
                    <p className='mb-0'>
                        {this.props.intl.formatMessage({
                            id: 'ROLES.VALIDATION.GENERIC_ERROR',
                        })}
                    </p>
                </Alert>
                <div class='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to='/roles'
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'LIST.BACK_TO_LIST',
                        })}
                    </Button>
                </div>
                <Form onSubmit={this.handleCreateRoleFormSubmit}>
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ROLES.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Input
                                    type='text'
                                    label={this.props.intl.formatMessage({
                                        id: 'ROLES.ROLE_NAME_FIELD_LABEL',
                                    })}
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'ROLES.ROLE_NAME_FIELD_PLACEHOLDER',
                                    })}
                                    value={this.state.name}
                                    onChange={this.handleNameChange}
                                    error={this.state.errors.name}
                                    isInvalid={this.state.errors.name}
                                />
                            </Form.Row>
                        </Card.Body>
                    </Card>
                    <Card className='mb-4'>
                        <Card.Header>
                            {this.props.intl.formatMessage({
                                id:
                                    'ROLES.PRIVILEGE_PROFILE_FORM_SECTION_TITLE',
                            })}
                        </Card.Header>
                        <Card.Body className='p-0'>
                            <Table className='mb-0'>
                                {this.state.permissionGroups
                                    .filter((x, i, a) => a.indexOf(x) === i)
                                    .sort()
                                    .map((group) => (
                                        <tr>
                                            <td>{group}</td>
                                            <td>
                                                {this.state.permissions
                                                    .filter(
                                                        (permission) =>
                                                            permission.group ===
                                                            group,
                                                    )
                                                    .map((permission) => (
                                                        <Form.Row>
                                                            <Form.Check
                                                                type='switch'
                                                                id={
                                                                    permission.code
                                                                }
                                                                label={
                                                                    permission.description
                                                                }
                                                                checked={this.state.role.permissions.find(
                                                                    (
                                                                        rolePermission,
                                                                    ) =>
                                                                        rolePermission.code ===
                                                                        permission.code,
                                                                )}
                                                                value={
                                                                    permission.code
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    this.handlePermissionToggle(
                                                                        e,
                                                                        permission,
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Row>
                                                    ))}
                                            </td>
                                        </tr>
                                    ))}
                            </Table>
                        </Card.Body>
                    </Card>
                    <div className='text-right'>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            type='submit'
                            loading={this.state.loading}
                            frontColor='white'
                            backColor='rgba(210, 212, 226, 0.5)'
                        >
                            {this.props.intl.formatMessage({
                                id: 'ROLES.SAVE_BUTTON_TEXT',
                            })}
                        </LoadingButton>
                    </div>
                </Form>
            </>
        );
    }
}
export default injectIntl(CreateRole);
