import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import Col from 'react-bootstrap/Col';
import Dropzone from 'react-dropzone';
import Form from 'react-bootstrap/Form';

const CustomDropzone = (props) => {
    const [file, setFile] = useState(props.file);
    const [iconUrl, setIconUrl] = useState('');

    const onDrop = (acceptedFiles, rejectedFiles) => {
        const newFile = acceptedFiles.map((file) => {
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
            });
        });

        setFile(newFile);

        props.onChange(newFile);
    };

    useEffect(() => {
        setIconUrl(props.iconUrl || '');
    }, [props.iconUrl]);

    return (
        <Col
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
        >
            <Dropzone accept='image/*' onDrop={onDrop} multiple={false}>
                {({getRootProps, getInputProps, isDragActive}) => (
                    <section>
                        <div
                            {...getRootProps()}
                            className={classNames('dropzone', {
                                'dropzone--isActive': isDragActive,
                            })}
                        >
                            <input {...getInputProps()} />
                            {!file?.length && !!iconUrl === false ? (
                                isDragActive ? (
                                    <p>{props.dropPlaceholder}</p>
                                ) : (
                                    <p>{props.dragPlacegolder}</p>
                                )
                            ) : null}

                            {file?.length || !!iconUrl ? (
                                <Form.Row>
                                    <Col>
                                        {file.length ? (
                                            file.map((file, i) => (
                                                <img
                                                    key={i}
                                                    className='img-fluid'
                                                    style={{
                                                        width: file.name.includes(
                                                            'svg',
                                                        )
                                                            ? '50%'
                                                            : '100%',
                                                    }}
                                                    src={file.preview}
                                                    alt={props.fileImageAlt}
                                                />
                                            ))
                                        ) : (
                                            <img
                                                className='img-fluid'
                                                style={{
                                                    width: iconUrl.includes(
                                                        'svg',
                                                    )
                                                        ? '50%'
                                                        : '100%',
                                                }}
                                                src={iconUrl}
                                                alt={props.fileImageAlt}
                                            />
                                        )}
                                    </Col>
                                </Form.Row>
                            ) : null}
                        </div>
                    </section>
                )}
            </Dropzone>
            {props.error ? (
                <Form.Text className='text-danger'>{props.error}</Form.Text>
            ) : null}
        </Col>
    );
};

export default CustomDropzone;
