import {API} from '../API';
import PaginatedList from '../PaginatedList';
import OrdersReports from '../../models/OrdersReports';
import Order from '../../models/Order';

const ordersReports = {
    get: async (
        page = 1,
        status = '',
        topic = '',
        userId = '',
        sellerId = '',
        startsAt = '',
        endsAt = '',
        isRead = '',
    ) => {
        const {data: response} = await API.get(
            `/order-cases/?page=${page}&status=${status}&topic=${topic}&userId=${userId}&sellerId=${sellerId}&startsAt=${startsAt}&endsAt=${endsAt}${
                isRead !== '' ? `&isRead=${isRead}` : ''
            }`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((i) => new OrdersReports(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/order-cases/${id}`);

        return {
            orderReport: new OrdersReports(response.data),
        };
    },
    refund: async (input, id) => {
        const {data: response} = await API.post(`/orders/${id}/refund`, input);

        return {
            order: new Order(response.data),
        };
    },
    review: async (input, id) => {
        const {data: response} = await API.post(
            `/order-cases/${id}/review`,
            input,
        );

        return {
            orderReport: new OrdersReports(response.data),
        };
    },
    read: async (id) => {
        await API.post(`/order-cases/${id}/read`);
        return true;
    },
};

export default ordersReports;
