export default class Role {
    id = 0;
    name = '';
    permissions = [];
    userCount = 0;

    constructor(id = 0, name = '', permissions = [], userCount = 0) {
        this.id = id;
        this.name = name;
        this.permissions = permissions;
        this.userCount = userCount;
    }
}
