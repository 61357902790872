import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink, Redirect} from 'react-router-dom';

import Form from '../../components/form/category/Form';
import API from '../../common/utils/API';
import Category from '../../common/models/Category';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LoadingButton from '../../components/form/LoadingButton';

export class EditCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldRedirectToIndex: false,
            shouldRedirectToDetail: false,
            isDeleteCategoryModalOpen: false,
            isLoadErrorModalOpen: false,
            loading: false,
            category: new Category(),
            attributes: [],
        };
    }

    componentDidMount() {
        return this.fetchCategory();
    }

    fetchCategory = async () => {
        this.setState({
            loading: true,
        });

        try {
            const [{category}, {attributes}] = await Promise.all([
                API.categories.detail(this.props.match.params.id),
                API.categories.getAttributes(),
            ]);

            this.setState({
                category,
                attributes,
                loading: false,
                isLoadErrorModalOpen: false,
            });
        } catch (e) {
            this.setState({
                isLoadErrorModalOpen: true,
                loading: false,
            });
        }
    };

    onSubmit = async (input) => {
        this.setState({
            loading: true,
        });

        try {
            const req = {...input};
            let iconUrl = this.state.category.iconUrl;
            if (!iconUrl) {
                const res = await this.handleSaveIcon(req.icon[0]);
                iconUrl = res.iconUrl;
            }

            req.iconUrl = iconUrl;
            req.name = {es: req.nameEs, en: req.nameEn};
            req.bgColor = req.bgColor.replace('#', '');
            req.fgColor = req.fgColor.replace('#', '');

            delete req.icon;
            delete req.nameEs;
            delete req.nameEn;

            await API.categories.update(req, this.props.match.params.id);

            this.setState({
                shouldRedirectToDetail: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'CATEGORIES.CATEGORY_SUCCESSFULLY_UPDATED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                loading: false,
            });
        }
    };

    handleSaveIcon = async (icon) => {
        const formData = new FormData();

        formData.append('picture', icon);

        return await API.media.save(formData);
    };

    handleDeleteCategory = async (e) => {
        try {
            await API.categories.delete(this.props.match.params.id);

            this.setState({
                shouldRedirectToIndex: true,
                redirectSuccessMessage: this.props.intl.formatMessage({
                    id: 'CATEGORIES.CATEGORY_SUCCESSFULLY_DELETED_MESSAGE',
                }),
            });
        } catch (e) {
            this.setState({
                isDeleteCategoryModalOpen: false,
                loading: false,
            });
        }
    };

    toggleDeleteCategoryModal = (show) => {
        this.setState({
            isDeleteCategoryModalOpen: show,
        });
    };

    render() {
        return this.state.shouldRedirectToDetail ? (
            <Redirect
                to={{
                    pathname: `/categories/${this.props.match.params.id}`,
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : this.state.shouldRedirectToIndex ? (
            <Redirect
                to={{
                    pathname: '/categories',
                    state: {
                        successMessage: this.state.redirectSuccessMessage,
                    },
                }}
            />
        ) : (
            <>
                <div className='mb-4 d-flex justify-content-end'>
                    <Button
                        as={NavLink}
                        to={`/categories/${this.props.match.params.id}`}
                        className='btn btn-dark btn-bold btn-light-dark'
                    >
                        {this.props.intl.formatMessage({
                            id: 'GENERAL.CANCEL',
                        })}
                    </Button>
                </div>
                <Form
                    data-test='editCategoryComponent'
                    category={this.state.category}
                    attributes={this.state.attributes}
                    handleDeleteCategory={this.handleDeleteCategory}
                    toggleDeleteCategoryModal={this.toggleDeleteCategoryModal}
                    isDeleteCategoryModalOpen={
                        this.state.isDeleteCategoryModalOpen
                    }
                    handleSubmit={this.onSubmit}
                    loading={this.state.loading}
                />
                <Modal show={this.state.isLoadErrorModalOpen} onHide={() => {}}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.intl.formatMessage({
                                id: 'CATEGORIES.CATEGORY_ERROR_MODAL_TITLE',
                            })}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.intl.formatMessage({
                            id: 'CATEGORIES.CATEGORY_ERROR_MODAL_TEXT',
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-bold btn-light-dark'
                            onClick={this.props.history.goBack}
                        >
                            {this.props.intl.formatMessage({
                                id:
                                    'CATEGORIES.CATEGORY_ERROR_MODAL_BACK_BUTTON',
                            })}
                        </Button>
                        <LoadingButton
                            variant='primary'
                            className='btn-bold'
                            loading={this.state.loading}
                            onClick={this.fetchCategory}
                        >
                            {this.props.intl.formatMessage({
                                id:
                                    'CATEGORIES.CATEGORY_ERROR_MODAL_RETRY_BUTTON',
                            })}
                        </LoadingButton>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default injectIntl(EditCategory);
