import React from 'react';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import StarterList from '../../components/starterList/StarterList';
import {ReturnStatus} from '../../common/config/constants';

export class ReturnList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            returns: [],
            page: 1,
            pageCount: 2,
            total: 0,
            searchTimeout: 0,
            userId: '',
            sellerId: '',
            startsAt: '',
            endsAt: '',
            refunded: '',
            status: '',
        };
    }

    componentDidMount() {
        return this.fetchReturns();
    }

    fetchReturns = async (page = 1) => {
        const result = await API.returns.get(
            page,
            this.state.userId,
            this.state.sellerId,
            this.state.startsAt,
            this.state.endsAt,
            this.state.refunded,
            this.state.status,
            this.state.reason,
        );

        this.setState({
            returns: result.items,
            page: result.page,
            pageSize: result.pageSize,
            pageCount: result.pageCount,
            total: result.total,
        });
    };

    onPageChange = (page) => {
        return this.fetchReturns(page);
    };

    onSearchChange = (event) => {
        if (this.state.searchTimeout) {
            clearTimeout(this.state.searchTimeout);
        }
        this.setState({
            [event.target.name]: event.target.value,
            searchTimeout: setTimeout(() => {
                return this.fetchReturns(1);
            }, 300),
        });
    };

    onAutocompleteOrDateSearchChange = (value, name) => {
        this.setState({
            [name]: value?.id || value || '',
            searchTimeout: setTimeout(() => {
                return this.fetchReturns(1);
            }, 300),
        });
    };

    onSearchClear = (field = 'search') => {
        this.setState(
            {
                [field]: '',
            },
            () => {
                return this.fetchReturns(1);
            },
        );
    };

    handleAutocompleteSearch = async (query) => {
        const {items: users} = await API.users.get(1, query);
        return users.map((user) => ({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
        }));
    };

    render() {
        return (
            <>
                <StarterList
                    data-test='returnListComponent'
                    hideSearchInput={true}
                    onSearchKeywordClear={this.onSearchClear}
                    onSearchKeywordChange={this.onSearchChange}
                    onAutocompleteOrDateSearchChange={
                        this.onAutocompleteOrDateSearchChange
                    }
                    handleAutocompleteSearch={this.handleAutocompleteSearch}
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    total={this.state.total}
                    data={{
                        headerTitles: [
                            '#',
                            this.props.intl.formatMessage({
                                id: 'RETURNS.BUYER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'RETURNS.SELLER_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'RETURNS.DATE_OF_PURCHASE_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id:
                                    'RETURNS.DATE_OF_RETURN_REQUEST_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'RETURNS.BUYER_EMAIL_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'RETURNS.SELLER_EMAIL_TABLE_HEADER',
                            }),
                            this.props.intl.formatMessage({
                                id: 'RETURNS.STATUS_TABLE_HEADER',
                            }),
                        ],
                        rows: this.state.returns.map((returnOrder) => ({
                            content: [
                                returnOrder.id,
                                returnOrder.buyerFullName,
                                returnOrder.sellerFullName,
                                new Date(
                                    returnOrder.order.createdAt,
                                ).toLocaleDateString(),
                                new Date(
                                    returnOrder.createdAt,
                                ).toLocaleDateString(),
                                returnOrder.user.email,
                                returnOrder.seller.email,
                                this.props.intl.formatMessage({
                                    id: `RETURNS.STATUS_${returnOrder.status.toUpperCase()}`,
                                }),
                            ],
                            background: returnOrder.isRead ? '' : '#89adee94',
                            link: `/returns/${returnOrder.id}`,
                        })),
                    }}
                    searchFields={[
                        {
                            name: 'userId',
                            value: this.state.userId,
                            autocomplete: true,
                            labelKey: 'name',
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.TYPE_HERE_TO_SEARCH_BY_USER',
                            }),
                        },
                        {
                            name: 'sellerId',
                            value: this.state.sellerId,
                            autocomplete: true,
                            labelKey: 'name',
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.TYPE_HERE_TO_SEARCH_BY_SELLER',
                            }),
                        },
                        {
                            name: 'startsAt',
                            value: this.state.startsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_FROM',
                            }),
                        },
                        {
                            name: 'endsAt',
                            value: this.state.endsAt,
                            date: true,
                            placeholder: this.props.intl.formatMessage({
                                id: 'LIST.DATE_TO',
                            }),
                        },
                        {
                            name: 'status',
                            value: this.state.status,
                            placeholder: this.props.intl.formatMessage({
                                id: 'RETURNS.STATUS_PLACEHOLDER',
                            }),
                            options: Object.values(ReturnStatus).map(
                                (value) => ({
                                    value,
                                    label: this.props.intl.formatMessage({
                                        id: `RETURNS.STATUS_${value.toUpperCase()}`,
                                    }),
                                }),
                            ),
                        },
                        {
                            name: 'refunded',
                            value: this.state.refunded,
                            placeholder: this.props.intl.formatMessage({
                                id: 'RETURNS.PAYMENT_PLACEHOLDER',
                            }),
                            options: [
                                {
                                    value: true,
                                    label: this.props.intl.formatMessage({
                                        id: `RETURNS.PAYMENT_REFUNDED`,
                                    }),
                                },
                                {
                                    value: false,
                                    label: this.props.intl.formatMessage({
                                        id: `RETURNS.PAYMENT_NOT_REFUNDED`,
                                    }),
                                },
                            ],
                        },
                    ]}
                />
            </>
        );
    }
}

export default injectIntl(ReturnList);
