import React, {useState} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Menu from './Menu';
import Image from 'react-bootstrap/Image';
import logo from './assets/logo-color.svg';
import * as Fi from 'react-icons/fi';
import './Sidebar.scss';

function Sidebar(props) {
    const [isSidebarOpenOnXL, setIsSidebarOpenOnXL] = useState(1);
    const [isSidebarOpenOnLG, setIsSidebarOpenOnLG] = useState(0);

    return (
        <div
            id='sidebar'
            className={`${isSidebarOpenOnXL ? 'sidebar-open-xl' : ''} ${
                isSidebarOpenOnLG ? 'sidebar-open-lg' : ''
            }`}
        >
            <Navbar expand='md'>
                <Navbar.Brand>
                    <Image
                        className='brand-image ml-4'
                        src={process.env.REACT_APP_LOGO_SIDEBAR ?? logo}
                        height='60'
                    />
                    <Button
                        onClick={() => setIsSidebarOpenOnXL(!isSidebarOpenOnXL)}
                        id='sidebar-toggle-xl'
                    >
                        {isSidebarOpenOnXL ? (
                            <Fi.FiChevronsLeft size='20' />
                        ) : (
                            <Fi.FiChevronsRight size='20' />
                        )}
                    </Button>
                    <Button
                        onClick={() => setIsSidebarOpenOnLG(!isSidebarOpenOnLG)}
                        variant='primary'
                        id='sidebar-toggle-lg'
                    >
                        {isSidebarOpenOnLG ? (
                            <Fi.FiChevronsLeft size='20' />
                        ) : (
                            <Fi.FiChevronsRight size='20' />
                        )}
                    </Button>
                    <div>
                        <Navbar.Toggle
                            id='sidebar-menu-toggle'
                            aria-controls='sidebar-menu-navbar-nav'
                        >
                            <Fi.FiSidebar size='20' />
                        </Navbar.Toggle>
                        <Button
                            id='top-menu-toggle'
                            onClick={props.onToggleTopMenu}
                        >
                            <Fi.FiMenu size='20' />
                        </Button>
                    </div>
                </Navbar.Brand>
                <div id='menu-container'>
                    <Navbar.Collapse id='sidebar-menu-navbar-nav'>
                        <Menu />
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </div>
    );
}

export default Sidebar;
