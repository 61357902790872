import {API} from '../API';
import PaginatedList from '../PaginatedList';
import Brand from '../../models/Brand';

const brands = {
    get: async (page = 1, search = '') => {
        const {data: response} = await API.get(
            `brands/?page=${page}&=search=${search}`,
        );

        const list = new PaginatedList();
        list.items = response.data.map((i) => new Brand(i));
        list.page = response.meta.page;
        list.pageCount = response.meta.lastPage;
        list.pageSize = response.meta.perPage;
        list.total = response.meta.total;

        return list;
    },
    detail: async (id) => {
        const {data: response} = await API.get(`/brands/${id}`);

        return {
            brand: new Brand(response.data),
        };
    },
    save: async (input) => {
        const {data: response} = await API.post('/brands', input);
        return {
            Brand: new Brand(response.data),
        };
    },
    update: async (input, id) => {
        const {data: response} = await API.put(`/brands/${id}`, input);
        return {
            brand: new Brand(response.data),
        };
    },
    delete: async (id) => {
        await API.delete(`/brands/${id}`);
        return {};
    },
};

export default brands;
