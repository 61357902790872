import React from 'react';
import {injectIntl} from 'react-intl';

import API from '../../common/utils/API';
import OrderList from '../../components/orderList/OrderList';
import Queue from '../../common/utils/Queue';

export class FaqOrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            faqs: [],
        };
    }

    componentDidMount() {
        return this.fetchFaqs();
    }

    fetchFaqs = async () => {
        try {
            const {faqs} = await API.faqs.all();

            this.setState({
                faqs,
            });
        } catch (e) {
            this.setState({
                faqs: [],
            });
        }
    };

    updateFaqsOrder = async (faqsOrdered) => {
        const faqs = {
            ids: faqsOrdered.map((faq) => faq.id),
        };

        return Queue.enqueue(() => API.faqs.updateOrder(faqs));
    };

    render() {
        return (
            <>
                <OrderList
                    data-test='faqOrderListComponent'
                    items={this.state.faqs}
                    updateFaqsOrder={this.updateFaqsOrder}
                    id='orderListDroppable'
                />
            </>
        );
    }
}

export default injectIntl(FaqOrderList);
