import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {Col} from 'react-bootstrap';
import * as FeatherIcons from 'react-icons/fi';

function Input(props) {
    const radios = props.radios || [];
    const style = {};

    return (
        <>
            <Form.Group
                style={style}
                as={Col}
                xs={props.xs}
                sm={props.sm}
                md={props.md}
                lg={props.lg}
                xl={props.xl}
                controlId={props.controlId}
            >
                {props.label ? <Form.Label>{props.label}</Form.Label> : null}
                {
                    {
                        text: <Form.Control {...props} />,

                        email: <Form.Control {...props} />,

                        password: (
                            <InputGroup>
                                <Form.Control
                                    {...props}
                                    type={
                                        props.hiddenToggle ? 'password' : 'text'
                                    }
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text
                                        id={'#inputGroupPrepend' + props.name}
                                        onClick={props.onClickToggle}
                                    >
                                        <FeatherIcons.FiEye
                                            hidden={!props.hiddenToggle}
                                        />
                                        <FeatherIcons.FiEyeOff
                                            hidden={props.hiddenToggle}
                                        />
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        ),

                        textarea: <Form.Control {...props} as={props.type} />,

                        select: (
                            <Form.Control
                                {...props}
                                as={props.type}
                                id={props.id}
                                onChange={props.onChange}
                                value={props.value}
                            >
                                <option value=''>{props.placeholder}</option>
                                {props.children}
                                {props.options?.length
                                    ? props.options.map(({value, label}) => (
                                          <option key={value} value={value}>
                                              {label}
                                          </option>
                                      ))
                                    : null}
                                {props.groupoptions?.length
                                    ? props.groupoptions.map(
                                          ({label: group, options = []}) => (
                                              <optgroup
                                                  key={group}
                                                  label={group}
                                              >
                                                  {options.map(
                                                      ({value, label}) => (
                                                          <option
                                                              key={value}
                                                              value={value}
                                                          >
                                                              {label}
                                                          </option>
                                                      ),
                                                  )}
                                              </optgroup>
                                          ),
                                      )
                                    : null}
                            </Form.Control>
                        ),

                        radio: (
                            <>
                                {radios.map((radio) => (
                                    <Form.Check
                                        custom
                                        type={props.type}
                                        id={radio.id}
                                        label={radio.label}
                                        checked={radio.value === props.value}
                                        value={radio.value}
                                        onChange={props.onChange}
                                    />
                                ))}
                            </>
                        ),

                        checkbox: (
                            <Form.Check
                                custom
                                label={props.placeholder}
                                key={props.id}
                                {...props}
                                onChange={props.onChange}
                            />
                        ),
                        switch: (
                            <Form.Check
                                custom
                                label={props.placeholder}
                                key={props.id}
                                {...props}
                                onChange={props.onChange}
                            />
                        ),
                    }[props.type]
                }

                {props.muted ? (
                    <Form.Text className='text-muted'>{props.muted}</Form.Text>
                ) : null}
                {props.error ? (
                    <Form.Text className='text-danger'>{props.error}</Form.Text>
                ) : null}
            </Form.Group>
        </>
    );
}
export default Input;
