import React from 'react';
import {injectIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Row, Col} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import SwiperCore, {Navigation, Pagination} from 'swiper';

import API from '../../common/utils/API';
import Can from '../../common/security/Can';
import Input from '../../components/form/Input';

class FeesList extends React.Component {
    constructor(props) {
        super();
        this.state = {
            fees: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.fetchAll();
    }

    fetchAll = async () => {
        this.setState({loading: true});

        try {
            const fees = await API.fees.all();

            this.setState({
                fees,
                loading: false,
            });
        } catch (error) {
            console.log(error);
        }
    };

    onValueChange = (event) => {
        const id = event.target.name;
        const value = event.target.value;
        const fees = [...this.state.fees];
        const fee = fees.find((fee) => fee.id == id);
        if (fee) {
            fee['newrate'] = value;
        }
        this.setState({fees: fees});
    };

    getFeeValue = (id) => {
        const fee = this.state.fees.find((fee) => fee.id == id);
        if (fee) {
            return fee.newrate !== undefined ? fee.newrate : fee.rate;
        }
        return 0;
    };

    onUpdateValue = async (e) => {
        var id = e.target.getAttribute('data-id');
        // find new value
        const fees = [...this.state.fees];
        const fee = fees.find((fee) => fee.id == id);
        // if value changed update it
        if (fee && fee.newrate) {
            try {
                const newFee = await API.fees.update({rate: fee.newrate}, id);
                fee.rate = newFee.rate;
                delete fee.newrate;
                this.setState({
                    fees: fees,
                });
            } catch (error) {
                console.log(error);
            }
        }
    };

    render() {
        return (
            <>
                <Card className='mb-4'>
                    <Card.Header>
                        {this.props.intl.formatMessage({
                            id:
                                'COMPLAINS.GENERAL_INFORMATION_FORM_SECTION_TITLE',
                        })}
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Value %</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.fees.map((fee) => (
                                    <tr key={fee.id}>
                                        <td>{fee.id}</td>
                                        <td>{fee.name.es}</td>
                                        <td>
                                            <Input
                                                type='text'
                                                name={fee.id}
                                                onChange={this.onValueChange}
                                                value={this.getFeeValue(fee.id)}
                                            />
                                        </td>
                                        <td>
                                            <Button
                                                data-id={fee.id}
                                                onClick={this.onUpdateValue}
                                            >
                                                Actualizar
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default injectIntl(FeesList);
